<template>
  <div class="scroll-div" v-if="data">
    <template v-for="(item, index) in data">
      <div :key="index">
        <h2 v-html="item.title"></h2>
        <hr />
        <div v-for="(section, idx) in item.sections" :key="idx">
          <h3 class="item" v-html="section.title"></h3>
          <p v-html="section.description"></p>
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    <h2 class="title-fall-back">Términos y Condiciones</h2>
    <ul class="terms-fall-back">
      <li>
        - Para saber sobre nuestros Términos y Condiciones, ingresá en:
        <a :href="terminos"> {{ terminos }} </a>
      </li>
      <li>
        - Para saber sobre nuestra Política de Privacidad, ingresá en:
        <a :href="polPriv"> {{ polPriv }} </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Legals",
  data() {
    return {
      selected: 0,
      legales: null,
      data: null,
      terminos: "https://telecentro.com.ar/terminos",
      polPriv: "https://telecentro.com.ar/politica-privacidad",
    };
  },
  created() {
    this.fetchData("https://telecentro.com.ar/api/terms")
      .then((data) => {
        this.data = data;
      })
      .catch(() => {
        this.fetchData("https://telecentro.com.ar/json/mockup/apiTerms.json")
          .then((data) => {
            this.data = data;
          })
          .catch((error) => {
            console.error(error);
          });
      });
  },
  methods: {
    fetchData(url) {
      return fetch(url).then((response) => {
        if (!response.ok) {
          //console.log("Error al obtener los datos", response);
          throw new Error("Error al obtener los datos");
        }
        return response.json();
      });
    },
  },
};
</script>
