<template>
  <div class="login">
    <transition name="fade">
      <div class="terms-container" v-if="show" v-on:click="show = !show">
        <div class="terms-title-container"></div>

        <div class="terms-condition">
          <!-- INICIO terminos y condiciones y politica de privacidad estáticos -->
          <!-- <TermsPolicy /> -->
          <!-- FIN -->
          <!-- INICIO terminos y condiciones y politica de privacidad DINAMICO -->
          <legals />
          <!-- FIN -->
        </div>
        <div class="btn-container">
          <button class="btn-close" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </transition>

    <main class="wrapper container-fluid" role="main">
      <section class="col-left">
        <div class="card card-login">
          <div class="card-header text-center">
            <img
              class="logo"
              src="../assets/images/t-play_logo.svg"
              width="175"
              height="78"
              alt="TPlay Logo"
            />
          </div>
          <div class="card-body">
            <form
              v-on:submit.prevent="login()"
              method="POST"
              class="form form-login"
              role="form"
              novalidate
            >
              <div class="form-group">
                <input
                  v-model="input.username"
                  type="email"
                  class="form-control form-control-username form-control-lg"
                  name="username"
                  id="username"
                  autocomplete="username"
                  placeholder="Tu correo electrónico de Sucursal Virtual"
                  required
                  tabindex="1"
                />
                <label for="username">
                  <i class="zmdi zmdi-account-circle"></i>
                  E-mail de Sucursal Virtual
                </label>
              </div>
              <div class="form-group">
                <input
                  v-model="input.password"
                  :type="showPassword"
                  class="form-control form-control-password form-control-lg"
                  name="password"
                  id="password"
                  autocomplete="password"
                  placeholder="Contraseña de Sucursal Virtual"
                  required
                  tabindex="2"
                />
                <label for="password">
                  <i class="zmdi zmdi-key"></i>
                  Contraseña de Sucursal Virtual
                </label>
                <a
                  v-on:click="togglePassword()"
                  href="#"
                  id="togglePassword"
                  class="toggle-pass"
                  title="Mostar / Ocultar contraseña."
                >
                  <i
                    class="zmdi"
                    v-bind:class="{
                      'zmdi-eye': showPassword === 'password',
                      'zmdi-eye-off': showPassword === 'text',
                    }"
                  ></i>
                </a>
              </div>
              <div class="forgot-pass">
                <a
                  href="https://telecentro.com.ar/login#recover"
                  title="Recupero de contraseña"
                  target="_blank"
                  rel="noopener"
                  >Olvidé mi contraseña</a
                >
              </div>
              <button
                type="submit"
                class="btn btn-primary float-right"
                :disabled="disabledButton"
                tabindex="2"
              >
                <template v-if="disabledButton">
                  <i class="zmdi zmdi-sign-in" aria-hidden="true"></i>
                  &nbsp;Continuar
                  <img
                    src="../assets/images/spinner.svg"
                    alt="spinner"
                    class="spinner"
                    aria-hidden="true"
                  />
                </template>
                <template v-else>
                  <i class="zmdi zmdi-sign-in" aria-hidden="true"></i>
                  &nbsp;Continuar
                </template>
              </button>
            </form>
          </div>
        </div>

        <div class="login-options">
          <span
            >¿No tenés usuario?
            <a
              href="https://telecentro.com.ar/login#onboarding"
              title="Registro de usuarios"
              target="_blank"
              rel="noopener"
              >Crealo ahora</a
            >
          </span>
          <span>
            <a
              href="https://ayuda.telecentro.com.ar/telecentro-play/"
              title="Ayuda"
              target="_blank"
              rel="noopener"
              >Necesito ayuda</a
            >
          </span>

          <div class="noclientes-message">
            <p>
              <span class="badge">No soy cliente</span>
              <a
                v-on:click.prevent="toSubscribe()"
                href="#"
                target="_blank"
                rel="noopener"
                >Subscribirme ahora.</a
              >
            </p>
          </div>
        </div>

        <div class="bottom-legals">
          <p>Telecentro Play | Versión {{ version }}</p>
          <span
            >&copy; {{ year }} Telecentro S.A. Todos los derechos reservados. -
            Al ingresar aceptas nuestros
          </span>
          <span class="terms" v-on:click="show = !show"
            >Términos y Condiciones y la Política de privacidad</span
          >
        </div>

        <div class="alert alert-danger alert-dismissible fade" role="alert">
          <strong>Hubo un error</strong>
          <!-- Por favor, revisá los datos que ingresaste. -->{{
            errorDescription
          }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            tabindex="-1"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </section>

      <section class="col-right">
        <div class="slideshow-wrapper">
          <div class="slideshow">
            <!-- <div class="slide slide-one">
                            <img src="../assets/images/login/banner-web-login-default.jpg" alt="banner" class="banner-promo"> -->
            <!-- <img src="../assets/images/login/banner-web-login-godzilla-2.jpg" alt="banner" class="banner-promo"> -->
            <!-- <img src="../assets/images/login/blob-shape0.svg" alt="blob" class="blob-shape blob0">
                            <img src="../assets/images/login/blob-shape1.svg" alt="blob" class="blob-shape blob1">
                            <img src="../assets/images/login/blob-shape2.svg" alt="blob" class="blob-shape blob2">
                            <img src="../assets/images/login/blob-shape3.svg" alt="blob" class="blob-shape blob3">

                            <img src="../assets/images/login/undraw_entertainment.svg" alt="illustration"
                                class="udw-illustration"> -->
            <!-- </div> -->

            <div
              :class="'slide slide-' + index"
              v-for="(banner, index) of banners"
              :key="index"
            >
              <img :src="banner" alt="banner" class="banner-promo" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Legals from "../components/Legals.vue";

import TermsPolicy from "../components/Terms-Policy.vue";
export default {
  name: "Login",
  components: {
    // TermsPolicy,
    Legals,
  },
  inject: ["modalConfirmShow"],
  data() {
    return {
      show: false,

      input: {
        username: "",
        password: "",
      },
      disabledButton: false,
      year: new Date().getFullYear(),
      errorDescription: "",
      showPassword: "password",
      banners: [],
    };
  },

  created() {
    this.getBanners().then((data) => {
      this.banners = data;
    });
  },

  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },

  mounted() {
    document.body.classList.add("login");

    this.$bus.$emit("show-loader", false);

    //Fix de desarrollo/tareas#1354
    //https://desarrollo.telecentro.net.ar/desarrollo/tareas/issues/1354
    setTimeout(function () {
      AutoFilledInputs.init();
      FloatLabel.init();
    }, 100);

    // let self = this;

    // telecentro.tplay.core.logOut(function(rsp){
    //     if(!rsp.status){
    //         console.log('No se pudo cerrar sesión.');
    //     }
    // });
  },

  beforeDestroy() {
    document.body.classList.remove("login");

    document.body.style.overflow = "";
  },

  methods: {
    async getBanners() {
      const res = await fetch(
        process.env.BASE_URL + "assets/banners_login.json"
      );
      const data = await res.json();
      return data;
    },
    toSubscribe() {
      gtag("event", "no_cliente", 1);
      window.open(
        "https://telecentro.com.ar/contratar-telecentro-play",
        "_blank"
      );
    },

    shake() {
      let form = document.querySelector(".form-login");
      let username = form.querySelector("input#username");

      form.classList.add("was-validated");

      this.disabledButton = false;

      username.focus();

      setTimeout(function () {
        form.classList.remove("was-validated");
      }, 2000);
    },

    showError() {
      let alert = document.querySelector(".alert.alert-danger");
      let username = document.querySelector("input#username");
      let password = document.querySelector("input#password");

      function remove() {
        alert.classList.remove("show");
        username.classList.remove("is-invalid");
        password.classList.remove("is-invalid");
      }

      alert.classList.add("show");
      username.classList.add("is-invalid");
      password.classList.add("is-invalid");

      this.disabledButton = false;

      setTimeout(remove, 2000);
    },

    login() {
      const remove = () => {
        document.querySelector(".float-right").classList.remove("loading");
        document.querySelector(".float-right").classList.remove("disabled");
      };

      this.disabledButton = true;

      document.querySelector(".float-right").classList.add("loading");
      document.querySelector(".float-right").classList.add("disabled");

      let form = document.querySelector(".form-login");

      if (form.checkValidity()) {
        let self = this;

        telecentro.tplay.core.login(
          this.input.username,
          this.input.password,
          function (res) {
            if (!res.status) {
              //console.log(res);

              //Primero chequeo si es algún mensaje especial:
              if (res.description && res.description == "ACTIVAR_CUENTA") {
                self.onActivarCuenta();
              } else {
                remove();
                self.disabledButton = false;
                // self.errorDescription = res.description
                if (res.code === -11 /*Max device reached*/) {
                  const textoDescripcion =
                    "Llegaste al límite de dispositivos disponibles para ver T-Play. Entrá a Sucursal Virtual y gestionalos www.telecentro.com.ar/sucursal-virtual";
                  self.$bus.$emit("show-toast", "error", textoDescripcion);
                } else {
                  self.$bus.$emit("show-toast", "error", res.description);
                }
                // self.showError();
              }

              self.shake();
            } else {
              self.disabledButton = false;
              remove();
              self.$router.replace({ path: "/inicio" });
            }
          },
          function (err) {
            self.$bus.$emit("show-toast", "error", err.description);
          }
        );
      } else {
        self.disabledButton = false;
        remove();
        this.shake();
      }
    },

    onActivarCuenta() {
      this.modalConfirmShow({
        type: "info",
        title: "Activación del servicio",

        content: `<p>
				Disfrutá del mejor entretenimiento en todos tus dispositivos:
				</p>
				<p>
				Activá Telecentro Play para poder llevar la tele en tu smartphone, tablet o computadora, ver programas en vivo, miles de series y películas, las últimas 24hs de programación, y mucho más…
				</p>`,

        acceptText: "Activar",

        confirm: function (ok) {
          if (ok) {
            gtag("event", "cliente_no_activado", 1);
            window.open(
              "https://telecentro.com.ar/sucursal-virtual/tv/telecentro-play",
              "_blank"
            );
          }
        },
      });
    },

    togglePassword() {
      this.showPassword =
        this.showPassword === "password"
          ? (this.showPassword = "text")
          : (this.showPassword = "password");
    },
  },
};

////////////////////////////////////////////////////////////////////////////////////////////////////
//Fix de desarrollo/tareas#1354
//https://desarrollo.telecentro.net.ar/desarrollo/tareas/issues/1354

const AutoFilledInputs = (() => {
  const AUTOFILLED = "is-autofilled";

  const onAutoFillStart = (el) => el.classList.add(AUTOFILLED);
  const onAutoFillCancel = (el) => el.classList.remove(AUTOFILLED);

  const onAnimationStart = ({ target, animationName }) => {
    switch (animationName) {
      case "onAutoFillStart":
        return onAutoFillStart(target);
      case "onAutoFillCancel":
        return onAutoFillCancel(target);
    }
  };

  const init = () => {
    const formContainers = document.querySelectorAll(".form-group");

    formContainers.forEach((element) => {
      if (element.querySelector("input")) {
        element.addEventListener("animationstart", onAnimationStart, false);
      }
    });
  };

  return {
    init: init,
  };
})();

const FloatLabel = (() => {
  // add active class and placeholder
  const handleFocus = (e) => {
    const target = e.target;
    target.parentNode.classList.add("active");
  };

  // remove active class and placeholder
  const handleBlur = (e) => {
    const target = e.target;
    if (!target.value) {
      target.parentNode.classList.remove("active");
    }
  };

  // register events
  const bindEvents = (element) => {
    const floatField = element.querySelector("input");
    floatField.addEventListener("focus", handleFocus);
    floatField.addEventListener("blur", handleBlur);
  };

  // get DOM elements
  const init = () => {
    const floatContainers = document.querySelectorAll(".form-group");

    floatContainers.forEach((element) => {
      if (element.querySelector("input").value) {
        element.classList.add("active");
      }

      bindEvents(element);
    });
  };

  return {
    init: init,
  };
})();
</script>
<style>
/* Se comentan estilos que no estan utilizando

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
} */

</style> 
