<template>
  <div class="terms-list__wrapper">
    <div class="terms-section">
      <h2>Términos y Condiciones</h2>
      <div class="inner-section pack-digital-hd-premium">
        <h3>Pack Digital, Pack HD y Productos Premium</h3>
        <p>
          <span
            >Los precios incluyen IVA y corresponden a los abonos mensuales por
            cada servicio. Sujeto a disponibilidad técnica y geográfica. No
            incluye el costo de la instalación de decodificadores digitales.
            Pack ORO: Abono Mensual de digitalización de señales $385 final.
            PACK Telecentro HD: Los precios incluyen IVA. Incluye las señales
            digitales. Exclusivo para clientes del servicio Telecentro Plan Full
            que abonen mediante débito automático con tarjeta de crédito. Para
            acceder a este pack, se puede adquirir el equipo Sagemcom DCIW303 HD
            , en comodato. Costo instalación: $1.500 para clientes nuevos y
            $1.500 para clientes existentes. Stock: 1.000 unidades. Además, para
            poder visualizar correctamente en alta definición, el cliente deberá
            contar con una pantalla del tipo HD Ready. Las señales de TV Abierta
            que se incluyen en el paquete HD son gratuitas y sin codificar. El
            valor del paquete HD (básico o Premium) está conformado sobre las
            restantes señales que lo integran. Telecentro S.A.''</span
          >
        </p>
      </div>
      <div class="inner-section velocidades-internet">
        <h3>Velocidades Internet</h3>
        <p>
          <span>
            <p>
              <b>Servicio 60 Megas:</b> Velocidad de bajada 60 Mbps y 15 Mbps de
              velocidad de subida, el servicio es para una descarga máxima
              mensual de 600 GB, una vez excedido este límite Telecentro se
              reserva el derecho de bajar la velocidad de bajada a 10 Mbps.
            </p>
            <p>
              <b>Servicio 150 Megas:</b> Velocidad de bajada 150 Mbps y 30 Mbps
              de velocidad de subida, el servicio es para una descarga máxima
              mensual de 2.000 GB, una vez excedido este límite Telecentro se
              reserva el derecho de bajar la velocidad de bajada a 10 Mbps.
            </p>
            <p>
              <b>Servicio 300 Megas:</b> Velocidad de bajada 300 Mbps y 30 Mbps
              de velocidad de subida, el servicio es para una descarga máxima
              mensual de 2.000 GB, una vez excedido este límite Telecentro se
              reserva el derecho de bajar la velocidad de bajada a 10 Mbps.
            </p>
            <p>
              <b>Servicio 500 Megas:</b> Velocidad de bajada 500 Mbps y 30 Mbps
              de velocidad de subida, el servicio es para una descarga máxima
              mensual de 5.000 GB, una vez excedido este límite Telecentro se
              reserva el derecho de bajar la velocidad de bajada a 10 Mbps.
            </p>
            <p>
              <b>Servicio 1.000 Megas:</b> Velocidad de bajada 1.000 Mbps y 30
              Mbps de velocidad de subida, el servicio es para una descarga
              máxima mensual de 10.000 GB, una vez excedido este límite
              Telecentro se reserva el derecho de bajar la velocidad de bajada a
              10 Mbps.
            </p>
          </span>
        </p>
      </div>
      <div class="inner-section Telecentro-play">
        <h3>Aplicación Telecentro Play</h3>
        <p>
          <span
            >Los clientes de Telecentro podrán acceder al servicio de Telecentro
            Play a través de las distintas aplicaciones disponibles para
            Android, iOS, navegadores web y otras plataformas cuando posean el
            servicio de Telecentro Play activo. Para los clientes que posean el
            Pack HD activo el costo de la aplicación se encontrará bonificado en
            tanto mantengan la suscripción a ese paquete. El servicio de
            Telecentro Play permite ver televisión en vivo en todas las
            plataformas, acceder a funcionalidades como pausar, retroceder y
            grabar la TV en vivo, volver a comenzar los programas en curso,
            volver a ver las últimas 24 horas de programación, y acceder a
            contenidos de series, TV, documentales e infantiles On Demand (a
            demanda del usuario). Estas funcionalidades pueden no estar
            disponibles en algunos de los canales de la grilla. La grilla de
            canales en la aplicación y la plataforma web de Telecentro Play
            puede variar con respecto a la disponible en el plan contratado por
            el cliente de Telecentro. Los contenidos de Star Premium, Pack HBO,
            Pack Fútbol y Hot Pack requieren la contratación previa de cualquier
            paquete Star Premium, Pack HBO, Pack Fútbol y Hot Pack. Los
            contenidos de Star Premium, Pack HBO, Pack Fútbol y Hot Pack
            dependerán de los paquetes premium contratados. Asimismo, es
            condición para acceder a tales contenidos la registración de usuario
            en la sucursal virtual de Telecentro. Telecentro recomienda, para
            una mejor visualización de los contenidos, una conexión a internet
            mayor a 10 megas. Algunos títulos no están disponibles en HD. Sujeto
            a eventuales restricciones.
          </span>
        </p>
      </div>
      <div class="inner-section T-Play">
        <h3>T-Play 42 Señales</h3>
        <p>
          <span
            >PRECIO: $457 FINAL POR MES. VIGENCIA HASTA EL 31/07/2022 O HASTA
            AGOTAR STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. EL SERVICIO
            INCLUYE 42 SEÑALES DE TELEVISIÓN EN VIVO Y SERIES Y PELÍCULAS "ON
            DEMAND" (A DEMANDA DEL USUARIO) PARA VER A TRAVES DE LA APLICACIÓN
            "T-PLAY" Y/O A TRAVES DEL SITIO WEB WWW.TELECENTROPLAY.COM.AR.
            ADEMAS DE LAS 42 SEÑALES DE TELEVISION EN VIVO EL CLIENTE PODRÁ
            ACCEDER A LOS PACKS PREMIUM QUE TENGA ACTIVO. SUJETO A
            DISPONIBILIDAD TÉCNICA Y GEOGRÁFICA.. EL CLIENTE PODRÁ DESCARGAR LA
            APLICACIÓN "T-PLAY" DESDE LA TIENDA DE TU SISTEMA OPERATIVO
            (DISPONIBLE PARA ANDROID Y IOS). EL CLIENTE PODRÁ VINCULAR HASTA UN
            MÁXIMO DE 3 DISPOSITIVOS PARA UTILIZAR EL SERVICIO Y VER EN FORMA
            SIMULTÁNEA HASTA EN 2 DISPOSITIVOS. EN CASO DE ARREPENTIMIENTO EL
            CLIENTE TIENE EL DERECHO IRRENUNCIABLE A REVOCAR LA ACEPTACIÓN DEL
            SERVICIO CONTRATADO DENTRO DE LOS DIEZ DÍAS COMPUTADOS A PARTIR DE
            LA SUSCRIPCIÓN AL SERVICIO. OFERTA SUJETA A CAMBIOS, UNA VEZ
            FINALIZADO EL PERIODO DE LA OFERTA, PREVIA NOTIFICACION DE
            TELECENTRO CON AL MENOS 30 DIAS DE ANTICIPACION. SI EL CLIENTE NO
            ESTUVIERE DE ACUERDO CON LA MODIFICACIÓN PROPUESTA PODRÁ RESCINDIR
            EL SERVICIO SIN CARGO, COMUNICANDO DICHA DECISIÓN A TELECENTRO.
            TELECENTRO S.A., AV. J. M. DE ROSAS 2860, SAN JUSTO, BUENOS AIRES,
            ARGENTINA. CUIT N° 30-64089726-7. TELÉFONO: 11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section servicios">
        <h3>Servicios</h3>
        <p>
          <span
            >Todos los servicios que presta Telecentro S.A. están sujetos a
            disponibilidad técnica y geográfica.''</span
          >
        </p>
      </div>
      <div class="inner-section T-Phone">
        <h3>T-Phone</h3>
        <p>
          <span
            >PRECIO: $299 FINAL POR MES. VIGENCIA HASTA EL 31/07/2022 O HASTA
            AGOTAR STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. EL SERVICIO
            CONSISTE EN LA ASIGNACIÓN DE UNA LÍNEA DE TELEFONÍA DIGITAL QUE EL
            CLIENTE PODRÁ UTILIZAR A TRAVÉS DE LA APLICACIÓN T-PHONE EN
            SMARTPHONES QUE TENGAN ANDROID Y iOS (EN ADELANTE LOS EQUIPOS)
            MEDIANTE CONEXIÓN A WIFI Y/O UTILIZANDO EL SERVICIO DE DATOS DEL
            TELÉFONO MÓVIL DEL CLIENTE. EL SERVICIO INCLUYE 50 MINUTOS MENSUALES
            PARA REALIZAR LLAMADAS SALIENTES A NÚMEROS DE TELEFONÍA FIJA DEL
            AMBA Y DE LARGA DISTANCIA NACIONAL, 100 MINUTOS MENSUALES PARA
            REALIZAR LLAMADAS SALIENTES A NÚMEROS DE TELEFONÍA MÓVIL, Y MINUTOS
            ILIMITADOS Y GRATUITOS PARA REALIZAR LLAMADAS SALIENTES A LÍNEAS DE
            TELÉFONO ASIGNADAS A CLIENTES DE TELECENTRO. TELECENTRO NO PROVEERÁ
            EQUIPOS AL CLIENTE PARA LA UTILIZACION DEL SERVICIO. EN CASO DE
            ARREPENTIMIENTO EL CLIENTE TIENE EL DERECHO IRRENUNCIABLE A REVOCAR
            LA ACEPTACIÓN DEL SERVICIO CONTRATADO DENTRO DE LOS DIEZ DÍAS
            COMPUTADOS A PARTIR DE LA SUSCRIPCIÓN AL SERVICIO. OFERTA SUJETA A
            CAMBIOS, UNA VEZ FINALIZADO EL PERIODO DE LA OFERTA, PREVIA
            NOTIFICACION DE TELECENTRO CON AL MENOS 30 DIAS DE ANTICIPACION. SI
            EL CLIENTE NO ESTUVIERE DE ACUERDO CON LA MODIFICACIÓN PROPUESTA
            PODRÁ RESCINDIR EL SERVICIO SIN CARGO, COMUNICANDO DICHA DECISIÓN A
            TELECENTRO. SUJETO A DISPONIBILIDAD TÉCNICA Y GEOGRÁFICA. TELECENTRO
            S.A., AV. J. M. DE ROSAS 2860, SAN JUSTO, BUENOS AIRES, ARGENTINA.
            CUIT N° 30-64089726-7. TELÉFONO: 11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section telefonia">
        <h3>Telefonía</h3>
        <p>
          <span
            >Estimado cliente del Servicio Telefónico: Cumplimos en informarle
            que el valor de las llamadas desde una línea origen fijo realizadas
            a celulares destino móvil del AMBA que actualmente tienen un valor
            de $0,90 + IVA el minuto, se verá incrementado, conforme se detalla
            a continuación: Telecom Personal S.A. (PERSONAL) (Sin banda horaria)
            A partir del 7/08/2021 | $1,973 + IVA por minuto. AMX Argentina S.A.
            (CLARO) (Sin banda horaria) A partir del 7/08/2021 | $1,973 + IVA
            por minuto. Telefónica Móviles Argentina S.A. (MOVISTAR) (Sin banda
            horaria) A partir del 7/08/2021 | $1,973 + IVA por minuto. Los
            cargos a celulares del Interior (no AMBA) son : Clave 1 : $ 2,998 +
            IVA por minuto (Sin banda horaria) Clave 2 y 3 : $ 3,192 + IVA por
            minuto (Sin banda horaria) Clave 4 y 5 : $ 3,443 + IVA por minuto
            (Sin banda horaria) Clave 6 a 12 : $ 3,765 + IVA por minuto (Sin
            banda horaria) Válido para Telecom Personal S.A. (Personal), AMX
            Argentina S.A. (Claro) y Telefónica Móviles Argentina S.A.
            (Movistar).
          </span>
        </p>
      </div>
      <div class="inner-section Disney">
        <h3>Disney+</h3>
        <p>
          <span
            >Oferta válida desde el 01/03/22 hasta el 31/07/2022 para clientes
            de servicios residenciales de Telecentro (en adelante el "Cliente").
            DISNEY+ consiste en un servicio de suscripción de "streaming" de
            video personalizado que incluye el sitio web, la aplicación y el
            contenido y los servicios relacionados de DISNEY+ (en adelante el
            "Servicio"). El Servicio lo brindará Walt Disney Company Argentina
            S.A. según las condiciones comerciales y técnicas establecidas en el
            Acuerdo de Suscripción de Walt Disney Company Argentina S.A. (en
            adelante, "Acuerdo de Suscripción") que el Cliente declara conocer y
            aceptar de manera expresa al momento de la contratación y a los que
            el Cliente puede acceder desde el siguiente enlace:
            <a
              href="https://www.disneyplus.com/es-419/legal/acuerdo-de-suscripci%C3%B3n"
              target="_blank"
              rel="noopener noreferrer"
              >https://www.disneyplus.com/es-419/legal/acuerdo-de-suscripci%C3%B3n</a
            >. El Servicio lo brindará Walt Disney Company Argentina S.A.
            mediante medios tecnológicos de su propiedad (app / Software). El
            Cliente declara conocer y aceptar de manera expresa que previo a la
            contratación del Servicio ha verificado disponer de los Dispositivos
            Compatibles necesarios para su utilización, conforme son definidos
            en el Acuerdo de Suscripción. El Servicio será facturado en forma
            mensual y consecutiva por Telecentro S.A. por cuenta y orden de Walt
            Disney Company Argentina S.A. DISNEY+ tiene una tarifa mensual de
            $385 (precio de marzo de 2022 con impuestos incluidos). El Cliente
            podrá cancelar sus suscripciones en cualquier momento ingresando a
            Telecentro.com.ar. En caso de arrepentimiento, el Cliente tiene el
            derecho irrenunciable a revocar la aceptación del Servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a Telecentro.com.ar y haciendo
            clic en el "Botón de Arrepentimiento". THE WALT DISNEY COMPANY
            ARGENTINA S.A., TUCUMÁN 1, PISO 4º, CABA, CUIT 30-63984459-1,
            WWW.DISNEYPLUS.COM. Promoción válida para la República Argentina.
            Sujeto a disponibilidad técnica y geográfica. PARA MÁS INFORMACIÓN
            CONSULTE EN WWW.TELECENTRO.COM.AR O AL 6380-9500. TELECENTRO S.A.,
            Av. Juan Manuel de Rosas 2860, San Justo, La Matanza. Provincia de
            Buenos Aires. CUIT N°: 30-64089726-7.
          </span>
        </p>
      </div>
      <div class="inner-section Star">
        <h3>Star+</h3>
        <p>
          <span
            >Oferta válida desde el 01/03/22 hasta el 31/07/2022 para clientes
            de servicios residenciales de Telecentro (en adelante el "Cliente").
            STAR+ consiste en un servicio de suscripción de un streaming de
            video personalizado que incluye el sitio web, la aplicación y el
            contenido y los servicios relacionados de STAR + (en adelante el
            "Servicio"). El Servicio lo brindará Walt Disney Company Argentina
            S.A. según las condiciones comerciales y técnicas establecidas en el
            Acuerdo de Suscripción de Walt Disney Company Argentina S.A. (en
            adelante, "Acuerdo de Suscripción") que el Cliente declara conocer y
            aceptar de manera expresa al momento de la contratación y a los que
            el Cliente puede acceder desde el siguiente enlace:
            <a
              href="https://www.disneyplus.com/es-419/legal/acuerdo-de-suscripci%C3%B3n"
              target="_blank"
              rel="noopener noreferrer"
              >https://www.disneyplus.com/es-419/legal/acuerdo-de-suscripci%C3%B3n</a
            >. El Servicio lo brindará Walt Disney Company Argentina S.A.
            mediante medios tecnológicos de su propiedad (app / Software). El
            Cliente declara conocer y aceptar de manera expresa que previo a la
            contratación ha verificado disponer de los Dispositivos Compatibles
            necesarios para su utilización, conforme son definidos en el Acuerdo
            de Suscripción. El Servicio será facturado en forma mensual y
            consecutiva por Telecentro S.A. por cuenta y orden de Walt Disney
            Company Argentina S.A. STAR + tiene una tarifa mensual de $880
            (precio de marzo de 2022 con impuestos incluidos). El Cliente podrá
            cancelar sus suscripciones en cualquier momento ingresando a
            Telecentro.com.ar. En caso de arrepentimiento, el Cliente tiene el
            derecho irrenunciable a revocar la aceptación del Servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a Telecentro.com.ar y haciendo
            clic en el "Botón de Arrepentimiento". THE WALT DISNEY COMPANY
            ARGENTINA S.A., TUCUMÁN 1, PISO 4º, CABA, CUIT 30-63984459-1,
            WWW.DISNEYPLUS.COM. Promoción válida para la República Argentina.
            Sujeto a disponibilidad técnica y geográfica. PARA MÁS INFORMACIÓN
            CONSULTE EN WWW.TELECENTRO.COM.AR O AL 6380-9500. TELECENTRO S.A.,
            Av. Juan Manuel de Rosas 2860, San Justo, La Matanza. Provincia de
            Buenos Aires. CUIT N°: 30-64089726-7.
          </span>
        </p>
      </div>
      <div class="inner-section combo">
        <h3>Combo+</h3>
        <p>
          <span
            >Oferta válida desde el 01/03/22 hasta el 31/07/2022 para clientes
            de servicios residenciales de Telecentro (en adelante el "Cliente").
            COMBO+ consiste en la suscripción conjunta a los servicios STAR+ y
            Disney + ofrecidos por Walt Disney Company Argentina S.A. (en
            adelante el "Servicio"). El Servicio lo brindará Walt Disney Company
            Argentina S.A. según las condiciones comerciales y técnicas
            establecidas en el Acuerdo de Suscripción de Walt Disney Company
            Argentina S.A. (en adelante, "Acuerdo de Suscripción") que el
            Cliente declara conocer y aceptar de manera expresa al momento de la
            contratación y a los que el Cliente puede acceder desde el siguiente
            enlace:<a
              href="https://www.disneyplus.com/es-419/legal/acuerdo-de-suscripci%C3%B3n"
              target="_blank"
              rel="noopener noreferrer"
              >https://www.disneyplus.com/es-419/legal/acuerdo-de-suscripci%C3%B3n</a
            >. El Servicio lo brindará Walt Disney Company Argentina S.A.
            mediante medios tecnológicos de su propiedad (app / Software). El
            Cliente declara conocer y aceptar de manera expresa que previo a la
            contratación ha verificado disponer de los Dispositivos Compatibles
            necesarios para su utilización, conforme son definidos en el Acuerdo
            de Suscripción. El Servicio será facturado en forma mensual y
            consecutiva por Telecentro S.A. por cuenta y orden de Walt Disney
            Company Argentina S.A. COMBO+ tiene una tarifa mensual de $995
            (precio de marzo de 2022 con impuestos incluidos). El Cliente podrá
            cancelar sus suscripciones en cualquier momento ingresando a
            Telecentro.com.ar. En caso de arrepentimiento, el Cliente tiene el
            derecho irrenunciable a revocar la aceptación del Servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a Telecentro.com.ar y haciendo
            clic en el "Botón de Arrepentimiento". THE WALT DISNEY COMPANY
            ARGENTINA S.A., TUCUMÁN 1, PISO 4º, CABA, CUIT 30-63984459-1,
            WWW.DISNEYPLUS.COM. Promoción válida para la República Argentina.
            Sujeto a disponibilidad técnica y geográfica. PARA MÁS INFORMACIÓN
            CONSULTE EN WWW.TELECENTRO.COM.AR O AL 6380-9500. TELECENTRO S.A.,
            Av. Juan Manuel de Rosas 2860, San Justo, La Matanza. Provincia de
            Buenos Aires. CUIT N°: 30-64089726-7.
          </span>
        </p>
      </div>
      <div class="inner-section datos-societarios">
        <h3>Datos Societarios</h3>
        <p>
          <span
            >Telecentro S.A. Juan Manuel de Rosas 2860, San Justo, Provincia de
            Buenos Aires, Argentina. CUIT 30-64089726-7.''
          </span>
        </p>
      </div>
      <div class="inner-section Hot Pack">
        <h3>Hot Pack</h3>
        <p>
          <span
            >PRECIO: $590 FINAL POR MES. VIGENCIA HASTA EL 31/07/2022 O HASTA
            AGOTAR STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. EL SERVICIO
            INCLUYE TRES SEÑALES CON PROGRAMACIÓN PARA ADULTOS. LOS PADRES,
            TUTORES O RESPONSABLES DE LOS MENORES DE EDAD QUE UTILICEN EL SITIO
            SERÁN RESPONSABLES POR DICHO USO, INCLUYENDO CUALQUIER CARGO,
            FACTURACIÓN O DAÑO QUE SE DERIVE DE ÉL. CANALES INCLUIDOS: PLAYBOY,
            VENUS, BRAZZERS TV Y ACCESO SIN CARGO A HOTGO. HOT GO ES LA
            PLATAFORMA ONLINE CON PROGRAMACIÓN DISPONIBLE DESDE CUALQUIER
            DISPOSITIVO CONECTADO AL SERVICIO DE DATOS MÓVILES Y/O WIFI. LA
            UTILIZACIÓN DEL SERVICIO HOTGO MEDIANTE DATOS MÓVILES IMPLICA EL
            CONSUMO DE LOS DATOS MÓVILES DEL PLAN CONTRATADO AL OPERADOR MÓVIL
            DEL CLIENTE. SUJETO A DISPONIBILIDAD TÉCNICA Y GEOGRÁFICA. PARA
            ACCEDER AL SERVICIO EL SUSCRIPTOR DEBE SER CLIENTE ACTIVO O
            CONTRATAR ALGUNO DE LOS PLANES DEL SERVICIO DE TELEVISION DIGITAL DE
            TELECENTRO. TELECENTRO S.A., AV. J. M. DE ROSAS 2860, SAN JUSTO,
            BUENOS AIRES, ARGENTINA. CUIT N° 30-64089726-7. TELÉFONO:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section Prime Video">
        <h3>Prime Video</h3>
        <p>
          <span
            >PROMOCIÓN VÁLIDA PARA LA REPÚBLICA ARGENTINA PARA CLIENTES ACTIVOS
            O FUTUROS DE TELECENTRO VIGENCIA HASTA EL 31/07/2022 O HASTA AGOTAR
            STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. SUJETO A
            DISPONIBILIDAD TÉCNICA Y GEOGRÁFICA. BONIFICACIÓN DEL 100% EN EL
            SERVICIO DE PRIME VIDEO (EN ADELANTE "PRIME VIDEO" Y/O EL
            "SERVICIO") DURANTE TRES MESES PARA CLIENTES DE TELECENTRO QUE
            CONTRATEN POR PRIMERA VEZ PRIME VIDEO. UNA VEZ FINALIZADO EL PERÍODO
            PROMOCIONAL PRIME VIDEO, SE RENOVARÁ MENSUALMENTE EN FORMA
            AUTOMÁTICA Y SUCESIVA Y APLICARÁ EL PAGO DEL PRECIO FINAL POR EL
            SERVICIO SEGÚN LAS CONDICIONES COMERCIALES VIGENTE ESTABLECIDAS EN
            LAS CONDICIONES DE USO Y VENTA DE PRIME VIDEO (EN ADELANTE "LAS
            CONDICIONES DE USO Y VENTA") PUBLICADAS EN EL SITIO WEB DE
            AMAZONVIDEO.COM. PRECIO CORRESPONDIENTE A MAYO DE 2021: $386 FINAL
            POR MES. EL SERVICIO SERÁ PRESTADO POR AMAZON.COM SERVICES LLC (EN
            ADELANTE AMAZON) MEDIANTE MEDIOS TECNOLÓGICOS DE SU PROPIEDAD (APP /
            SOFTWARE) SEGÚN LAS CONDICIONES COMERCIALES ESTABLECIDAS EN LAS
            CONDICIONES DE USO Y VENTA DE PRIME VIDEO QUE EL CLIENTE DECLARA
            CONOCER Y ACEPTAR DE MANERA EXPRESA AL MOMENTO DE LA CONTRATACIÓN.
            EL SERVICIO CONTEMPLADO EN LA PROMOCION PRECEDENTE SERÁ FACTURADO
            POR TELECENTRO S.A. POR CUENTA Y ORDEN DE AMAZON. EL CLIENTE PODRÁ
            CANCELAR SU SUSCRIPCION EN CUALQUIER MOMENTO INGRESANDO A
            TELECENTRO.COM.AR. EN CASO DE ARREPENTIMIENTO EL CLIENTE TIENE EL
            DERECHO IRRENUNCIABLE A REVOCAR LA ACEPTACIÓN DEL SERVICIO
            CONTRATADO DENTRO DE LOS DIEZ DÍAS COMPUTADOS A PARTIR DE LA
            SUSCRIPCIÓN AL SERVICIO. OFERTA SUJETA A CAMBIOS, FINALIZADO EL
            PERIODO PROMOCIONAL, CONFORME LAS CONDICIONES DE USO Y VENTA DE
            PRIME VIDEO. TELECENTRO S.A., AV. J. M. DE ROSAS 2860, SAN JUSTO,
            BUENOS AIRES, ARGENTINA. CUIT N° 30-64089726-7. TELÉFONO:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section HBO">
        <h3>Pack HBO</h3>
        <p>
          <span
            >PRECIO: $630 FINAL POR MES. VIGENCIA HASTA EL 31/07/2022 O HASTA
            AGOTAR STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. EL SERVICIO
            INCLUYE LAS SIGUIENTES SEÑALES: HBO, HBO2, HBO PLUS, HBO FAMILY, HBO
            SIGNATURE, HBO POP, HBO XTREME Y HBO MUNDI. SUJETO A DISPONIBILIDAD
            TÉCNICA Y GEOGRÁFICA. PARA ACCEDER AL SERVICIO EL SUSCRIPTOR DEBE
            SER CLIENTE ACTIVO O CONTRATAR ALGUNO DE LOS PLANES DEL SERVICIO DE
            TELEVISION DIGITAL DE TELECENTRO. TELECENTRO S.A., AV. J. M. DE
            ROSAS 2860, SAN JUSTO, BUENOS AIRES, ARGENTINA. CUIT N°
            30-64089726-7. TELÉFONO: 11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section plan-full-oro-hd-10M">
        <h3>TV + Internet 10 Megas</h3>
        <p>
          <span
            >Precio final TV + Internet 10 Megas $2.299, exclusivo para nuevos
            clientes residenciales, para pago con débito automático (DAT) de las
            siguientes tarjetas de crédito: Visa, Amex, otras consultar. El
            precio publicado corresponde al producto Telecentro TV + Internet 10
            Megas, compuesto por los servicios de televisión digital, telefonía
            digital e internet wifi 10 megas.
          </span>
        </p>
      </div>
      <div class="inner-section Futbol">
        <h3>Pack Fútbol</h3>
        <p>
          <span
            >PRECIO: $1290 FINAL POR MES. VIGENCIA HASTA EL 31/07/2022 O HASTA
            AGOTAR STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. EL SERVICIO
            INCLUYE: TNT SPORTS Y ESPN PREMIUM. SUJETO A DISPONIBILIDAD TÉCNICA
            Y GEOGRÁFICA. PARA ACCEDER AL SERVICIO EL SUSCRIPTOR DEBE SER
            CLIENTE ACTIVO O CONTRATAR ALGUNO DE LOS PLANES DEL SERVICIO DE
            TELEVISION DIGITAL DE TELECENTRO. TELECENTRO S.A., AV. J. M. DE
            ROSAS 2860, SAN JUSTO, BUENOS AIRES, ARGENTINA. CUIT N°
            30-64089726-7. TELÉFONO: 11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section plan-full-hd-60Megas">
        <h3>TV + Internet Premium 60 Megas</h3>
        <p>
          <span
            >Precio final Plan TV + Internet Premium 60 Megas $2.799 , exclusivo
            para nuevos clientes residenciales, para pago con débito automático
            (DAT) de las siguientes tarjetas de crédito: Visa, Amex, otras
            consultar. El precio publicado corresponde al producto Telecentro
            Plan TV + Internet Premium 60 Megas, compuesto por los servicios de
            televisión digital, telefonía digital e internet wifi 60 megas. La
            promoción HD gratis por 3 meses incluye televisión digital,
            telefonía digital, internet wifi 60 megas y Pack HD bonificado por 3
            meses. A partir del cuarto mes se comenzará a facturar el costo
            total del Pack HD según el precio vigente en ese momento, siendo el
            valor actual de $872. La promoción HD gratis por 3 meses es válida
            hasta el 31/07/2022 o hasta agotar stock de 5.000 unidades, lo que
            ocurra primero.
          </span>
        </p>
      </div>
      <div class="inner-section plan-full-teletrabajo-150M">
        <h3>TV + Internet Premium 150 Megas</h3>
        <p>
          <span
            >Precio final Plan TV + Internet Premium 150 megas $3.349 ,
            exclusivo para nuevos clientes residenciales, para pago con débito
            automático (DAT) de las siguientes tarjetas de crédito: Visa, Amex,
            otras consultar. El precio publicado corresponde al producto
            Telecentro Plan TV + Internet Premium 150 megas, compuesto por los
            servicios de televisión digital, telefonía digital e internet wifi
            150 megas. La promoción HD gratis por 3 meses incluye televisión
            digital, telefonía digital, internet wifi 150 megas y Pack HD
            bonificado por 3 meses. A partir del cuarto mes se comenzará a
            facturar el costo total del Pack HD según el precio vigente en ese
            momento, siendo el valor actual de $872. Las promocion HD gratis por
            3 meses es válida hasta el 31/07/2022 o hasta agotar stock de 5.000
            unidades, lo que ocurra primero.
          </span>
        </p>
      </div>
      <div class="inner-section plan-full-oro-500M">
        <h3>TV + Internet Premium 500 Megas</h3>
        <p>
          <span
            >Precio final Plan TV + Internet Premium 500 Megas $4.499 ,
            exclusivo para nuevos clientes residenciales, para pago con débito
            automático (DAT) de las siguientes tarjetas de crédito: Visa, Amex,
            otras consultar. El precio publicado corresponde al producto
            Telecentro Plan TV + Internet Premium 500 megas, compuesto por los
            servicios de televisión digital, telefonía digital e internet wifi
            500 megas. La promoción HD gratis por 3 meses incluye televisión
            digital, telefonía digital, internet wifi 500 megas y Pack HD
            bonificado por 3 meses. A partir del cuartomes se comenzará a
            facturar el costo total del Pack HD según el precio vigente en ese
            momento, siendo el valor actual de $872. La promocion HD gratis por
            3 meses es válida hasta el 31/07/2022 o hasta agotar stock de 5.000
            unidades, lo que ocurra primero.
          </span>
        </p>
      </div>
      <div class="inner-section plan-full-1000-megas">
        <h3>TV + Internet Premium 1.000 Megas</h3>
        <p>
          <span
            >Precio final Plan TV + Internet Premium 1.000 Megas $4.999
            exclusivo para nuevos clientes residenciales, para pago con débito
            automático (DAT) de las siguientes tarjetas de crédito: Visa, Amex,
            otras consultar. El precio publicado corresponde al producto
            Telecentro Plan TV + Internet Premium 1.000 Megas, compuesto por los
            servicios de televisión digital, Pack HD, telefonía digital e
            Internet banda ancha cablemódem 1000 Megas. La promoción HD gratis
            por 3 meses incluye televisión digital, telefonía digital, internet
            wifi 1.000 megas y Pack HD bonificado por 3 meses. A partir del
            cuarto mes se comenzará a facturar el costo total del Pack HD según
            el precio vigente en ese momento, siendo el valor actual de $872. La
            promocion HD gratis por 3 meses es válida hasta el 31/07/2022 o
            hasta agotar stock de 5.000 unidades, lo que ocurra primero.
          </span>
        </p>
      </div>
      <div class="inner-section tv-internet-300">
        <h3>TV + Internet Premium 300 Megas</h3>
        <p>
          <span
            >Precio final Plan TV + Internet Premium 300 Megas $3.699, exclusivo
            para nuevos clientes residenciales, para pago con débito automático
            (DAT) de las siguientes tarjetas de crédito: Visa, Amex, otras
            consultar. El precio publicado corresponde al producto Telecentro
            Plan TV + Internet Premium 300 megas, compuesto por los servicios de
            televisión digital, telefonía digital e internet wifi 300 megas. La
            promoción HD gratis por 3 meses incluye televisión digital,
            telefonía digital, internet wifi 300 megas y Pack HD bonificado por
            3 meses. A partir del cuartomes se comenzará a facturar el costo
            total del Pack HD según el precio vigente en ese momento, siendo el
            valor actual de $872. La promocion HD gratis por 3 meses es válida
            hasta el 31/07/2022 o hasta agotar stock de 5.000 unidades, lo que
            ocurra primero.
          </span>
        </p>
      </div>
      <div class="inner-section Promo500Mb">
        <h3>Promo Internet Premium 500 Megas</h3>
        <p>
          <span
            >Oferta valida en la República Argentina, sujeta a disponibilidad
            técnica y geográfica, para contrataciones efectuadas entre el
            07/07/22 y el 31/07/2022 o hasta agotar stock de 5.000 unidades, lo
            que ocurra primero. La Oferta consiste en la contratación del plan
            INTERNET PREMIUM 500 Megas al precio correspondiente al plan
            INTERNET PREMIUM 300 Megas (en adelante la ¿Promoción¿). Vigencia de
            la Promoción: 6 meses desde la finalización de los primeros 30 días
            sin cargo, a contar desde la instalación del servicio. Finalizado el
            período promocional el plan INTERNET PREMIUM 500 Megas, se facturará
            en forma mensual y sucesiva al precio de lista vigente en ese
            momento (Precio del plan Internet Premium 500 Megas sin promoción
            vigente a julio de 2022: $2.999 final por mes.). El Plan INTERNET
            PREMIUM 500 MEGAS incluye: I) Internet wifi de hasta 500 megas de
            velocidad de bajada y 30 Megas de velocidad de subida. Telecentro
            entregará módem en comodato. II) Primeros 30 días del Plan sin
            cargo, a contar desde la instalación del servicio. III) Servicio de
            telefonía fija: Incluye 2.500 minutos por mes (no acumulables
            mensualmente) para llamadas locales a teléfonos fijos y llamadas sin
            cargo entre líneas de Telecentro. Las llamadas a líneas de telefonía
            móvil, de larga distancia nacional y larga distancia internacional
            se facturarán conforme a las tarifas vigentes al momento de las
            llamadas. IV) Telecentro Wifi: se otorgan 50 GB sin cargo para
            utilizar el servicio Telecentro WiFi en la vía pública bajo la
            siguiente modalidad: 25 GB para utilizar en el transcurso del mes en
            el cual se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a julio de 2022: $383 final por
            mes). V) El cliente podrá activar el servicio Prime Video de Amazon,
            accediendo a la bonificación del precio del servicio durante los 3
            primeros meses desde su activación. Finalizado el período
            promocional Prime Video se renovará y facturará en forma mensual y
            sucesiva al precio de lista vigente en ese momento (Precio
            correspondiente a julio de 2022: $386 final por mes). Oferta
            exclusiva para nuevos clientes residenciales. Precio mensual final
            del plan INTERNET PREMIUM 300 Megas vigente a julio de 2022: $2.099
            final por mes. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a www.telecentro.com.ar y
            presionando en el BOTÓN DE ARREPENTIMIENTO. Para más información
            consulte en www.telecentro.com.ar/terminos, Títulos: TELECENTRO WIFI
            y PRIME VIDEO. TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San
            Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section promo500mbalexa">
        <h3>Promo TV + Internet 500 Megas + Telecentro Alexa</h3>
        <p>
          <span
            >Oferta válida solo para la República Argentina, sujeta a
            disponibilidad técnica y geográfica, desde el 13/07/2022 hasta el
            31/07/2022 o hasta agotar stock de 5.000 unidades, lo que ocurra
            primero. La Oferta es exclusiva para nuevas contrataciones del
            segmento residencial. El Plan TV + Internet 500 megas +Telecentro
            con Alexa integrada (en adelante el ¿plan¿), incluye los servicios
            de televisión digital, telefonía digital e internet wifi de hasta
            500 megas de velocidad de bajada y 30 Megas de velocidad de subida.
            Precio final mensual del plan: $4.999. Telecentro entregará módem y
            decodificadores en comodato. La oferta incluye: I) Instalación del
            servicio sin cargo. II) Primeros 30 días del Plan sin cargo, a
            contar desde la instalación del servicio. III) Televisión digital
            que incluye: un Decodificador Video Sound Box con acceso al servicio
            Alexa de Amazon y control remoto por voz, y un decodificador con
            resolución HD. IV) Pack HD: Primeros 3 meses sin cargo desde la
            activación del servicio. Finalizado el período promocional el Pack
            HD se renovará y facturará en forma mensual y sucesiva al precio de
            lista vigente en ese momento (Precio correspondiente a julio de
            2022: $872 final por mes). V) El servicio de telefonía incluye 2.500
            minutos por mes (no acumulables mensualmente) para llamadas locales
            a teléfonos fijos y llamadas sin cargo entre líneas de Telecentro.
            Las llamadas a líneas de telefonía móvil, de larga distancia
            nacional y larga distancia internacional se facturarán conforme a
            las tarifas vigentes al momento de las llamadas. V) T-play: Primeros
            3 meses de servicio sin cargo, a contar desde la activación del
            servicio. Finalizado el período promocional el servicio T-play se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento (Precio correspondiente a julio de 2022: $398
            final por mes). VI) Telecentro Wifi: se otorgan 50 GB sin cargo para
            utilizar el servicio Telecentro WiFi en la vía pública bajo la
            siguiente modalidad: 25 GB para utilizar en el transcurso del mes en
            el cual se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a julio de 2022: $383 final por
            mes). VII) El cliente podrá activar el servicio de Amazon Prime
            Video accediendo a una bonificación del precio mensual del servicio,
            sobre los 3 primeros meses desde la activación del servicio.
            Finalizado el período promocional Amazon Prime Video se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a julio de 2022: $386 final por
            mes). VIII) Pack HBO: Primeros 2 meses sin cargo desde la activación
            del servicio y próximos 3 meses subsiguientes bonificación del 50%
            del precio de lista vigente en ese momento. Finalizado el período
            promocional el Pack HBO se renovará y facturará en forma mensual y
            sucesiva al precio de lista vigente en ese momento. (Precio
            correspondiente a julio de 2022: $630 final por mes). IX) El cliente
            podrá activar Hot Pack accediendo a una bonificación del precio
            total mensual del servicio correspondiente a los 2 primeros meses
            desde la activación del servicio y a una bonificación del 50% del
            precio mensual de lista vigente en ese momento, durante los próximos
            2 meses subsiguientes. Finalizado el período promocional Hot Pack se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento (Precio correspondiente a julio de 2022: $590
            final por mes). En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a Telecentro.com.ar y haciendo
            clic en el Botón de Arrepentimiento. Para más información consulte
            en www.telecentro.com.ar/terminos. Títulos: Pack Digital, Pack HD y
            Productos Premium, Aplicación Telecentro Play, Telefonía, Telecentro
            WiFi, Amazon Prime Video, Pack HBO y Hot Pack. TELECENTRO S.A. Av.
            Juan Manuel de Rosas 2860, San Justo, Buenos Aires, Argentina. CUIT
            nro. 30-64089726-7. Teléfono: 11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section TelecentroWiFi">
        <h3>Telecentro WiFi</h3>
        <p>
          <span
            >PRECIO: $383 FINAL POR MES. VIGENCIA HASTA EL 31/07/2022 O HASTA
            AGOTAR STOCK DE 5.000 UNIDADES, LO QUE OCURRA PRIMERO. 1) 25 GB PARA
            UTILIZAR EL SERVICIO TELECENTRO WIFI EN LA VÍA PÚBLICA. PRECIO: $383
            FINAL POR MES. 2) 50 GB PARA UTILIZAR EL SERVICIO TELECENTRO WIFI EN
            LA VÍA PÚBLICA. PRECIO: $737 FINAL POR MES. 3) 100 GIGAS PARA
            UTILIZAR EL SERVICIO TELECENTRO WIFI EN LA VÍA PÚBLICA. PRECIO:
            $1099. EL SERVICIO DE TELECENTRO WIFI (EN ADELANTE EL "SERVICIO"),
            CONSISTE UNA CUOTA MENSUAL DE 25/50/100 GIGABYTES ("GB") DE DATOS
            PARA UTILIZAR VÍA RED DE WIFI EN TODOS LOS PUNTOS TELECENTRO WIFI
            DISPONIBLES EN LA CIUDAD AUTÓNOMA DE BUENOS AIRES Y EL GRAN BUENOS
            AIRES A TRAVES DE LOS DISPOSITIVOS DEL CLIENTE (EQUIPO QUE UTILIZA
            EL CLIENTE PARA CONECTARSE AL SERVICIO). EL SERVICIO SERÁ UTILIZADO
            PARA USO EXCLUSIVO DEL CLIENTE, CON UN LÍMITE DE 3 (TRES)
            DISPOSITIVOS A CONECTAR, DE CONFORMIDAD Y CON LOS ALCANCES
            ESTABLECIDOS EN LOS PRESENTES TYCGS. LA CUOTA DE GB DEL CLIENTE
            TIENE UNA VALIDEZ DE USO MENSUAL, REINICIÁNDOSE AL COMIENZO DE CADA
            PERÍODO. LOS GB NO CONSUMIDOS EN EL PERÍODO MENSUAL ASIGNADO SE
            VENCEN Y NO PODRÁN SER ACUMULADOS NI UTILIZADOS EN LOS PERÍODOS
            MENSUALES SIGUIENTES. PARA PODER ACCEDER AL SERVICIO, EL CLIENTE
            DEBERÁ CONECTARSE A LA RED PÚBLICA "TELECENTRO WIFI" Y AUTENTICARSE
            A TRAVÉS DEL PORTAL CAUTIVO DE ACCESO. EL CLIENTE NO PODRÁ ACCEDER,
            EN FORMA SIMULTÁNEA, DESDE DOS O MÁS DISPOSITIVOS. EL CLIENTE ACEPTA
            Y ES RESPONSABLE DE VERIFICAR EL CONSUMO Y CONTROL DE LOS GB
            ASIGNADOS PARA NAVEGAR EN LA RED DEL SERVICIO. EN CASO DE CONEXIÓN
            AUTOMÁTICA DE LOS DISPOSITIVOS A LA RED CITADA LOS GB CONSUMIDOS
            SERÁN DESCONTADOS DE LA BOLSA ADQUIRIDA, POR LO QUE EL CLIENTE
            ACEPTA EXPRESAMENTE QUE PUEDEN GENERARSE CONSUMOS DE GB AUTOMÁTICOS
            POR CONEXIONES DE ESTE TIPO. AL LLEGAR AL LÍMITE DE CONSUMO MENSUAL
            DE GB, EL CLIENTE NO PODRÁ CONTINUAR UTILIZANDO EL SERVICIO. SIN
            PERJUICIO DE LO ANTERIOR EL CLIENTE PODRÁ ADQUIRIR A TRAVÉS LA
            SUCURSAL VIRTUAL DE TELECENTRO UNA CANTIDAD DE 25 GB ADICIONALES
            PARA CONSUMIR DENTRO DEL MISMO PERÍODO MENSUAL, ASOCIANDO UN NUEVO
            DISPOSITIVO. EL CLIENTE PODRÁ ASOCIAR HASTA 3 (TRES) DISPOSITIVOS
            POR CORREO ELECTRÓNICO ASOCIADO A LA SUCURSAL VIRTUAL. EQUIPAMIENTO
            MOVIL DEL CLIENTE: LOS DISPOSITIVOS TERMINALES QUE UTILICE EL
            CLIENTE DEBEN CUMPLIR COMO MÍNIMO CON EL ESTÁNDAR WIFI IEEE 802. 11N
            PARA PODER UTILIZAR EL SERVICIO. EL MANTENIMIENTO Y USO DE LOS
            DISPOSITIVOS ES EXCLUSIVA RESPONSABILIDAD DEL CLIENTE. EL CLIENTE ES
            RESPONSABLE DE LA CONFIGURACIÓN DE LOS DISPOSITIVOS, SIENDO SU
            OBLIGACIÓN APLICAR LAS CONFIGURACIONES NECESARIAS EN SUS
            DISPOSITIVOS PARA UNA CORRECTA UTILIZACIÓN DEL SERVICIO.
            RESPONSABILIDAD: EL CLIENTE SE COMPROMETE A NO EFECTUAR CONEXIONES
            NO AUTORIZADAS A LA RED DE TELECENTRO, A USAR EL SERVICIO
            EXCLUSIVAMENTE CON LOS FINES PREVISTOS EN LOS PRESENTES TERMINOS Y
            CONDICIONES DEL SERVICIO, NO PUDIENDO ACTUAR COMO REVENDEDOR,
            EFECTUAR CONEXIONES PEER TO PEER (P2P), OBSTACULIZAR DE MANERA
            DIRECTA O INDIRECTA LA PRESTACIÓN DEL SERVICIO, NI EFECTUAR UN USO
            INDEBIDO DEL MISMO. TELECENTRO NO SE HACE RESPONSABLE DE LA
            INFORMACIÓN CONTENIDA EN LOS DOCUMENTOS, GRÁFICOS, AUDIOS, VIDEOS
            PUBLICADOS EN LOS SITIOS QUE VISITEN O CONSULTE EL CLIENTE.
            TELECENTRO NO SERÁ RESPONSABLE POR LOS DAÑOS QUE PUDIERAN SURGIR,
            DIRECTA O INDIRECTAMENTE, POR LA UTILIZACIÓN DE ESTE SERVICIO CON
            RELACIÓN A CUALQUIER FALLA O VIRUS. TELECENTRO NO GARANTIZA LA
            AUSENCIA DE VIRUS O CUALQUIER ACTIVIDAD DESTRUCTIVA (HACKING) POR
            PARTE DE TERCEROS DURANTE LA CONEXIÓN AL PRESENTE SERVICIO.
            TELECENTRO NO ASUME RESPONSABILIDAD EN CASO DE QUE EL SERVICIO SEA
            SUSPENDIDO TEMPORAL O PERMANENTE POR FALLAS OCASIONADAS POR
            PROBLEMAS TÉCNICOS, FALTA DE CORRIENTE ELÉCTRICA, FENÓMENOS
            NATURALES, DAÑOS POR TERCEROS, Y AQUELLOS QUE SE PRESENTEN EN RAZÓN
            DE LAS CONSIDERACIONES TÉCNICAS DETALLADAS A CONTINUACIÓN: EL
            CLIENTE TOMA CONOCIMIENTO Y ACEPTA QUE EL SERVICIO NO ES CONTRATADO
            COMO LA OPCIÓN PRINCIPAL DE CONEXIÓN DEL DISPOSITIVO DEL USUARIO,
            POR LO TANTO TELECENTRO NO PUEDE ASEGURAR LA CONTINUIDAD
            ININTERRUMPIDA DEL SERVICIO BASADO EN LAS SIGUIENTES CONSIDERACIONES
            TÉCNICAS: - CONECTIVIDAD: POR SER UNA RED ABIERTA Y COMPARTIDA
            TELECENTRO NO ASEGURA LA CONECTIVIDAD AL SERVICIO. LA CONECTIVIDAD
            DEPENDERÁ DE LA CONEXIÓN A LOS PUNTOS DE ACCESO WIFI Y LA
            DISPONIBILIDAD DE LOS MISMOS. - ESTABILIDAD: POR SER UNA RED ABIERTA
            Y COMPARTIDA TELECENTRO NO ASEGURA LA ESTABILIDAD DEL SERVICIO. -
            MOVILIDAD: EL SERVICIO BRINDADO NO ES MÓVIL, PORQUE LA TECNOLÓGÍA
            WIFI NO LO PERMITE. - ANCHO DE BANDA: POR SER UNA RED ABIERTA Y
            COMPARTIDA TELECENTRO NO ASEGURA LA ESTABILIDAD DE UN ANCHO DE BANDA
            FIJO. TELECENTRO NO SERÁ RESPONSABLE POR EL NO FUNCIONAMIENTO O MAL
            FUNCIONAMIENTO DE APLICACIONES PROVISTAS POR TERCERAS PARTES QUE
            REQUIERAN LA UTILIZACIÓN DE UNA IP PÚBLICA ASIGNADA DIRECTAMENTE
            SOBRE EL DISPOSITIVO DEL CLIENTE (SEA UNA PC O HARDWARE ESPECIFICO).
            EN PARTICULAR, DADO QUE LA TECNOLOGÍA CONOCIDA COMO NAT (NETWORK
            ADDRESS TRANSLATION) SE UTILIZA PARA COMPARTIR UNA IP PÚBLICA ENTRE
            VARIAS LPS PRIVADAS, EXISTE LA POSIBILIDAD QUE CIERTAS APLICACIONES
            NO FUNCIONEN SIMULTÁNEAMENTE EN MÁS DE UNA PC. CABE ACLARAR QUE ESTA
            NO ES UNA LIMITACIÓN IMPUESTA POR EL SERVICIO, OBJETO DE LOS
            PRESENTES TERMINOS Y CONDICIONES GENERALES DEL SERVICIO, SINO POR EL
            SERVICIO AL CUAL SE INTENTA ACCEDER O LA APLICACIÓN QUE SE INTENTA
            UTILIZAR. EL CLIENTE ASUME PLENA RESPONSABILIDAD FRENTE A TELECENTRO
            Y TERCEROS POR LOS DAÑOS Y PERJUICIOS DE TODA CLASE QUE SE GENEREN
            COMO CONSECUENCIA DEL ACCIONAR PROPIO, DE SUS DEPENDIENTES O DE
            TERCEROS CONECTADOS A TRAVÉS DEL SERVICIO DEL CLIENTE, QUE RESULTEN
            O CUANDO SEAN UTILIZADOS COMO INSTRUMENTOS DE HECHOS ILÍCITOS,
            DEBIENDO EL CLIENTE INDEMNIZAR Y MANTENER INDEMNE A TELECENTRO ANTE
            CUALQUIER RECLAMO DE PAGO QUE PUDIERA CORRESPONDER EN LOS SUPUESTOS
            INDICADOS. EL PRESENTE SERVICIO ESTÁ DIRIGIDO A PERSONAS FÍSICAS CON
            CAPACIDAD CIVIL PLENA. EL USO POR MENORES E INCAPACES ES EXCLUSIVA
            RESPONSABILIDAD DE LOS PADRES O TUTORES, QUIEN SERÁN LOS
            RESPONSABLES ÚLTIMOS DE ACEPTACIÓN DE LOS PRESENTES TÉRMINOS, COMO
            ASÍ TAMBIÉN DE LOS USOS QUE LOS MENORES O INCAPACES REALICEN SOBRE
            LOS CONTENIDOS QUE OFRECE ESTE SERVICIO. LOS PRESENTES TERMINOS Y
            CONDICIONES DE SERVICIO SE ENTENDERÁN ACEPTADOS POR EL CLIENTE CON
            EL ALTA DEL SERVICIO A TRAVÉS DE LA SUCURSAL VIRTUAL DE LA PÁGINA
            WEB DE TELECENTRO. EN CASO DE ARREPENTIMIENTO EL CLIENTE TIENE EL
            DERECHO IRRENUNCIABLE A REVOCAR LA ACEPTACIÓN DEL SERVICIO
            CONTRATADO DENTRO DE LOS DIEZ DÍAS COMPUTADOS A PARTIR DE LA
            SUSCRIPCIÓN AL SERVICIO. OFERTA SUJETA A CAMBIOS, UNA VEZ FINALIZADO
            EL PERIODO DE LA OFERTA, PREVIA NOTIFICACION DE TELECENTRO CON AL
            MENOS 30 DIAS DE ANTICIPACION. SI EL CLIENTE NO ESTUVIERE DE ACUERDO
            CON LA MODIFICACIÓN PROPUESTA PODRÁ RESCINDIR EL SERVICIO SIN CARGO,
            COMUNICANDO DICHA DECISIÓN A TELECENTRO. ADICIONALMENTE Y CON LA
            COMPRA DE CUALQUIER PLAN DE SERVICIO DE INTERNET, POR UNICA VEZ SE
            OTORGAN 50 GB SIN CARGO PARA UTILIZAR EL SERVICIO TELECENTRO WIFI EN
            LA VÍA PUBLICA BAJO LA SIGUIENTE MODALIDAD: 25 GB PARA UTILIZAR
            HASTA EL ULTIMO DIA DEL MES EN EL CUAL SE INSTALE EL SERVICIO Y 25
            GB PARA UTILIZAR DURANTE EL MES INMEDIATAMENTE SIGUIENTE. TELECENTRO
            S.A., AV. J. M. DE ROSAS 2860, SAN JUSTO, BUENOS AIRES, ARGENTINA.
            CUIT N° 30-64089726-7. TELÉFONO: 11-6380-9500.</span
          >
        </p>
      </div>
      <div class="inner-section pack-inicial">
        <h3>Pack Inicial TV OTT + Internet 60 Megas</h3>
        <p>
          <span
            >Promoción válida en la República Argentina desde el 01/11/2021
            hasta el 31/07/2022 o hasta agotar stock de 5.000 unidades, lo que
            ocurra primero. Sujeto a disponibilidad técnica y geográfica. El
            precio de $ 1.599 final por mes incluye servicio de tv (52 señales),
            servicio de internet wifi de 60 megas y servicio de telefonía fija.
            Velocidad de bajada de hasta 60 mbps y de hasta 15 mbps de velocidad
            de subida. Módem entregado en comodato. El servicio de telefonía
            incluye 2.500 minutos por mes (no acumulables mensualmente) para
            llamadas locales a teléfonos fijos. Llamadas a líneas de telefonía
            móvil, de larga distancia nacional y larga distancia internacional
            se facturarán conforme a las tarifas vigentes al momento de las
            llamadas. El servicio de tv digital incluye hasta un decodificador
            en comodato para utilizar en un televisor. Servicio exclusivo para
            uso personal, no comercial. Para más información consulte en
            www.telecentro.com.ar. TELECENTRO S.A. Av. Juan Manuel de Rosas
            2860, San Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7.
            Teléfono: 11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section alexa-150">
        <h3>TV + Internet 150 Megas + Telecentro Alexa</h3>
        <p>
          <span
            >Promoción válida solo para la República Argentina, desde el
            28/04/2022 hasta el 31/07/2022 o hasta agotar stock de 1000
            unidades, lo que ocurra primero. Sujeto a disponibilidad técnica y
            geográfica. Oferta exclusiva para nuevos clientes residenciales. El
            Plan TV + Internet 150 megas + Telefonía, incluye los servicios de
            televisión digital, telefonía digital e internet wifi de hasta 150
            megas de velocidad de bajada y 30 Megas de velocidad de subida.
            Precio final mensual del plan: $3.849. Telecentro entregará módem en
            comodato. Costo de instalación: $12.000 por única vez, en un pago
            por anticipado con tarjeta de crédito Visa, Mastercard o American
            Express. Consulte precio de instalación financiado con pago mediante
            tarjeta de crédito. Para más información comuníquese al teléfono
            6380-0000. La promoción incluye: I) Primeros 30 días de del Plan TV
            + Internet 150 megas + Telefonía sin cargo, a contar desde la
            instalación del servicio. II) Televisión digital a través de un
            Decodificador Video Sound Box para un Televisor, entregado por
            Telecentro en comodato. El decodificador Video Sound Box incluye
            acceso al servicio Alexa de Amazon y control remoto por voz. III)
            Pack HD: Primeros 3 meses sin cargo desde la activación del
            servicio. Finalizado el período promocional el Pack HD se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento. IV) El servicio de telefonía incluye 2.500 minutos por
            mes (no acumulables mensualmente) para llamadas locales a teléfonos
            fijos y llamadas sin cargo entre líneas de Telecentro. Las llamadas
            a líneas de telefonía móvil, de larga distancia nacional y larga
            distancia internacional se facturarán conforme a las tarifas
            vigentes al momento de las llamadas. V) Pack HBO: Primeros 2 meses
            sin cargo desde la activación del servicio y próximos 3 meses
            subsiguientes bonificación del 50% del precio de lista vigente en
            ese momento. Finalizado el período promocional el Pack HBO se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VI) Telecentro Wifi: se otorgan 50 GB sin
            cargo para utilizar el servicio Telecentro WiFi en la vía pública
            bajo la siguiente modalidad: 25 GB para utilizar en el transcurso
            del mes en el cual se instale el servicio y 25 GB para utilizar
            durante el mes inmediatamente siguiente. Finalizado el período
            promocional el servicio de Telecentro Wifi en vía pública, que
            incluye una cuota mensual de 25 GB para la utilización de este
            servicio, se renovará y facturará en forma mensual y sucesiva al
            precio de lista vigente en ese momento. VII) El cliente podrá
            activar el servicio de Amazon Prime Video accediendo a una
            bonificación en los 3 primeros meses desde la activación del
            servicio. Finalizado el período promocional Amazon Prime Video se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VIII) El cliente podrá activar Hot Pack
            accediendo a una bonificación del precio total mensual del servicio
            correspondiente a los 2 primeros meses desde la activación del
            servicio y a una bonificación del 50% del precio mensual de lista
            vigente en ese momento, durante los próximos 2 meses subsiguientes.
            Finalizado el período promocional Hot Pack se renovará y facturará
            en forma mensual y sucesiva al precio de lista vigente en ese
            momento. En caso de arrepentimiento, el cliente tiene el derecho
            irrenunciable a revocar la aceptación del servicio contratado dentro
            de los diez días computados a partir de la suscripción al servicio,
            ingresando a Telecentro.com.ar y haciendo clic en el ¿Botón de
            Arrepentimiento¿. Para más información consulte en
            www.telecentro.com.ar/terminos Títulos: ¿Pack Digital, Pack HD y
            Productos Premium¿, ¿Aplicación Telecentro Play¿, ¿Telefonía¿, ¿Pack
            HBO¿, ¿Telecentro WiFi", ¿Amazon Prime Video¿ y ¿Hot Pack¿.
            TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San Justo, Buenos
            Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono: 11-6380-9500.
            Costo de instalación financiado con tarjeta de crédito:
            <br />
            <b>VISA y MASTERCARD</b>

            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.492,40</td>
                    <td>$ 13.477,20</td>
                    <td>$ 1.477,20</td>
                    <td>75,00%</td>
                    <td>102.99%</td>
                    <td>138,59%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.450,00</td>
                    <td>$ 14.700,00</td>
                    <td>$ 2.700,00</td>
                    <td>75,00%</td>
                    <td>103,64%</td>
                    <td>142,52%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.795,47</td>
                    <td>$ 16.159,20</td>
                    <td>$ 4.159,20</td>
                    <td>78,00%</td>
                    <td>109,68%</td>
                    <td>155,56%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.464,80</td>
                    <td>$ 17.577,60</td>
                    <td>$ 5.577,60</td>
                    <td>78,00%</td>
                    <td>109,80%</td>
                    <td>159,97%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b>AMERICAN EXPRESS</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.514,00</td>
                    <td>$ 13.542,00</td>
                    <td>$ 1.524,00</td>
                    <td>77,00%</td>
                    <td>108,00%</td>
                    <td>145,97%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.470,40</td>
                    <td>$ 14.822,40</td>
                    <td>$ 2.822,40</td>
                    <td>77,00%</td>
                    <td>107,97%</td>
                    <td>149,18%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.824,00</td>
                    <td>$ 16.416,00</td>
                    <td>$ 4.416,00</td>
                    <td>81,00%</td>
                    <td>116,07%</td>
                    <td>166,04%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.494,20</td>
                    <td>$ 17930,40</td>
                    <td>$ 5.930,40</td>
                    <td>81,00%</td>
                    <td>115,98%</td>
                    <td>170,69%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </p>
      </div>
      <div class="inner-section alexa-300">
        <h3>TV + Internet 300 Megas + Telecentro Alexa</h3>
        <p>
          <span
            >Promoción válida solo para la República Argentina, desde el
            28/04/2022 hasta el 31/07/2022 o hasta agotar stock de 1000
            unidades, lo que ocurra primero. Sujeto a disponibilidad técnica y
            geográfica. Oferta exclusiva para nuevos clientes residenciales. El
            Plan TV + Internet 300 megas + Telefonía, incluye los servicios de
            televisión digital, telefonía digital e internet wifi de hasta 300
            megas de velocidad de bajada y 30 Megas de velocidad de subida.
            Precio final mensual del plan: $4.699. Telecentro entregará módem en
            comodato. Costo de instalación: $12.000 por única vez, en un pago
            por anticipado con tarjeta de crédito Visa, Mastercard o American
            Express. Consulte precio de instalación financiado con pago mediante
            tarjeta de crédito. Para más información comuníquese al teléfono
            6380-0000. La promoción incluye: I) Primeros 30 días de del Plan TV
            + Internet 300 megas + Telefonía sin cargo, a contar desde la
            instalación del servicio. II) Televisión digital a través de un
            Decodificador Video Sound Box para un Televisor, entregado por
            Telecentro en comodato. El decodificador Video Sound Box incluye
            acceso al servicio Alexa de Amazon y control remoto por voz. III)
            Pack HD: Primeros 3 meses sin cargo desde la activación del
            servicio. Finalizado el período promocional el Pack HD se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento. IV) El servicio de telefonía incluye 2.500 minutos por
            mes (no acumulables mensualmente) para llamadas locales a teléfonos
            fijos y llamadas sin cargo entre líneas de Telecentro. Las llamadas
            a líneas de telefonía móvil, de larga distancia nacional y larga
            distancia internacional se facturarán conforme a las tarifas
            vigentes al momento de las llamadas. V) Pack HBO: Primeros 2 meses
            sin cargo desde la activación del servicio y próximos 3 meses
            subsiguientes bonificación del 50% del precio de lista vigente en
            ese momento. Finalizado el período promocional el Pack HBO se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VI) Telecentro Wifi: se otorgan 50 GB sin
            cargo para utilizar el servicio Telecentro WiFi en la vía pública
            bajo la siguiente modalidad: 25 GB para utilizar en el transcurso
            del mes en el cual se instale el servicio y 25 GB para utilizar
            durante el mes inmediatamente siguiente. Finalizado el período
            promocional el servicio de Telecentro Wifi en vía pública, que
            incluye una cuota mensual de 25 GB para la utilización de este
            servicio, se renovará y facturará en forma mensual y sucesiva al
            precio de lista vigente en ese momento. VII) El cliente podrá
            activar el servicio de Amazon Prime Video accediendo a una
            bonificación en los 3 primeros meses desde la activación del
            servicio. Finalizado el período promocional Amazon Prime Video se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VIII) El cliente podrá activar Hot Pack
            accediendo a una bonificación del precio total mensual del servicio
            correspondiente a los 2 primeros meses desde la activación del
            servicio y a una bonificación del 50% del precio mensual de lista
            vigente en ese momento, durante los próximos 2 meses subsiguientes.
            Finalizado el período promocional Hot Pack se renovará y facturará
            en forma mensual y sucesiva al precio de lista vigente en ese
            momento. En caso de arrepentimiento, el cliente tiene el derecho
            irrenunciable a revocar la aceptación del servicio contratado dentro
            de los diez días computados a partir de la suscripción al servicio,
            ingresando a Telecentro.com.ar y haciendo clic en el ¿Botón de
            Arrepentimiento¿. Para más información consulte en
            www.telecentro.com.ar/terminos Títulos: ¿Pack Digital, Pack HD y
            Productos Premium¿, ¿Aplicación Telecentro Play¿, ¿Telefonía¿, ¿Pack
            HBO¿, ¿Telecentro WiFi", ¿Amazon Prime Video¿ y ¿Hot Pack¿.
            TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San Justo, Buenos
            Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono: 11-6380-9500.
            Costo de instalación financiado con tarjeta de crédito:
            <br />
            <b>VISA y MASTERCARD</b>

            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.492,40</td>
                    <td>$ 13.477,20</td>
                    <td>$ 1.477,20</td>
                    <td>75,00%</td>
                    <td>102.99%</td>
                    <td>138,59%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.450,00</td>
                    <td>$ 14.700,00</td>
                    <td>$ 2.700,00</td>
                    <td>75,00%</td>
                    <td>103,64%</td>
                    <td>142,52%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.795,47</td>
                    <td>$ 16.159,20</td>
                    <td>$ 4.159,20</td>
                    <td>78,00%</td>
                    <td>109,68%</td>
                    <td>155,56%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.464,80</td>
                    <td>$ 17.577,60</td>
                    <td>$ 5.577,60</td>
                    <td>78,00%</td>
                    <td>109,80%</td>
                    <td>159,97%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b>AMERICAN EXPRESS</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.514,00</td>
                    <td>$ 13.542,00</td>
                    <td>$ 1.524,00</td>
                    <td>77,00%</td>
                    <td>108,00%</td>
                    <td>145,97%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.470,40</td>
                    <td>$ 14.822,40</td>
                    <td>$ 2.822,40</td>
                    <td>77,00%</td>
                    <td>107,97%</td>
                    <td>149,18%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.824,00</td>
                    <td>$ 16.416,00</td>
                    <td>$ 4.416,00</td>
                    <td>81,00%</td>
                    <td>116,07%</td>
                    <td>166,04%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.494,20</td>
                    <td>$ 17930,40</td>
                    <td>$ 5.930,40</td>
                    <td>81,00%</td>
                    <td>115,98%</td>
                    <td>170,69%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </p>
      </div>
      <div class="inner-section alexa-500">
        <h3>TV + Internet 500 Megas + Telecentro Alexa</h3>
        <p>
          <span
            >Promoción válida solo para la República Argentina, desde el
            28/04/2022 hasta el 31/07/2022 o hasta agotar stock de 1000
            unidades, lo que ocurra primero. Sujeto a disponibilidad técnica y
            geográfica. Oferta exclusiva para nuevos clientes residenciales. El
            Plan TV + Internet 500 megas + Telefonía, incluye los servicios de
            televisión digital, telefonía digital e internet wifi de hasta 500
            megas de velocidad de bajada y 30 Megas de velocidad de subida.
            Precio final mensual del plan: $4.529. Telecentro entregará módem en
            comodato. Costo de instalación: $12.000 por única vez, en un pago
            por anticipado con tarjeta de crédito Visa, Mastercard o American
            Express. Consulte precio de instalación financiado con pago mediante
            tarjeta de crédito. Para más información comuníquese al teléfono
            6380-0000. La promoción incluye: I) Primeros 30 días de del Plan TV
            + Internet 500 megas + Telefonía sin cargo, a contar desde la
            instalación del servicio. II) Televisión digital a través de un
            Decodificador Video Sound Box para un Televisor, entregado por
            Telecentro en comodato. El decodificador Video Sound Box incluye
            acceso al servicio Alexa de Amazon y control remoto por voz. III)
            Pack HD: Primeros 3 meses sin cargo desde la activación del
            servicio. Finalizado el período promocional el Pack HD se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento. IV) El servicio de telefonía incluye 2.500 minutos por
            mes (no acumulables mensualmente) para llamadas locales a teléfonos
            fijos y llamadas sin cargo entre líneas de Telecentro. Las llamadas
            a líneas de telefonía móvil, de larga distancia nacional y larga
            distancia internacional se facturarán conforme a las tarifas
            vigentes al momento de las llamadas. V) Pack HBO: Primeros 2 meses
            sin cargo desde la activación del servicio y próximos 3 meses
            subsiguientes bonificación del 50% del precio de lista vigente en
            ese momento. Finalizado el período promocional el Pack HBO se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VI) Telecentro Wifi: se otorgan 50 GB sin
            cargo para utilizar el servicio Telecentro WiFi en la vía pública
            bajo la siguiente modalidad: 25 GB para utilizar en el transcurso
            del mes en el cual se instale el servicio y 25 GB para utilizar
            durante el mes inmediatamente siguiente. Finalizado el período
            promocional el servicio de Telecentro Wifi en vía pública, que
            incluye una cuota mensual de 25 GB para la utilización de este
            servicio, se renovará y facturará en forma mensual y sucesiva al
            precio de lista vigente en ese momento. VII) El cliente podrá
            activar el servicio de Amazon Prime Video accediendo a una
            bonificación en los 3 primeros meses desde la activación del
            servicio. Finalizado el período promocional Amazon Prime Video se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VIII) El cliente podrá activar Hot Pack
            accediendo a una bonificación del precio total mensual del servicio
            correspondiente a los 2 primeros meses desde la activación del
            servicio y a una bonificación del 50% del precio mensual de lista
            vigente en ese momento, durante los próximos 2 meses subsiguientes.
            Finalizado el período promocional Hot Pack se renovará y facturará
            en forma mensual y sucesiva al precio de lista vigente en ese
            momento. En caso de arrepentimiento, el cliente tiene el derecho
            irrenunciable a revocar la aceptación del servicio contratado dentro
            de los diez días computados a partir de la suscripción al servicio,
            ingresando a Telecentro.com.ar y haciendo clic en el ¿Botón de
            Arrepentimiento¿. Para más información consulte en
            www.telecentro.com.ar/terminos Títulos: ¿Pack Digital, Pack HD y
            Productos Premium¿, ¿Aplicación Telecentro Play¿, ¿Telefonía¿, ¿Pack
            HBO¿, ¿Telecentro WiFi", ¿Amazon Prime Video¿ y ¿Hot Pack¿.
            TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San Justo, Buenos
            Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono: 11-6380-9500.
            Costo de instalación financiado con tarjeta de crédito:
            <br />
            <b>VISA y MASTERCARD</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.492,40</td>
                    <td>$ 13.477,20</td>
                    <td>$ 1.477,20</td>
                    <td>75,00%</td>
                    <td>102.99%</td>
                    <td>138,59%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.450,00</td>
                    <td>$ 14.700,00</td>
                    <td>$ 2.700,00</td>
                    <td>75,00%</td>
                    <td>103,64%</td>
                    <td>142,52%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.795,47</td>
                    <td>$ 16.159,20</td>
                    <td>$ 4.159,20</td>
                    <td>78,00%</td>
                    <td>109,68%</td>
                    <td>155,56%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.464,80</td>
                    <td>$ 17.577,60</td>
                    <td>$ 5.577,60</td>
                    <td>78,00%</td>
                    <td>109,80%</td>
                    <td>159,97%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b>AMERICAN EXPRESS</b>

            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.514,00</td>
                    <td>$ 13.542,00</td>
                    <td>$ 1.524,00</td>
                    <td>77,00%</td>
                    <td>108,00%</td>
                    <td>145,97%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.470,40</td>
                    <td>$ 14.822,40</td>
                    <td>$ 2.822,40</td>
                    <td>77,00%</td>
                    <td>107,97%</td>
                    <td>149,18%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.824,00</td>
                    <td>$ 16.416,00</td>
                    <td>$ 4.416,00</td>
                    <td>81,00%</td>
                    <td>116,07%</td>
                    <td>166,04%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.494,20</td>
                    <td>$ 17930,40</td>
                    <td>$ 5.930,40</td>
                    <td>81,00%</td>
                    <td>115,98%</td>
                    <td>170,69%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </p>
      </div>
      <div class="inner-section alexa-1000">
        <h3>TV + Internet 1.000 Megas + Telecentro Alexa</h3>
        <p>
          <span
            >Promoción válida solo para la República Argentina, desde el
            28/04/2022 hasta el 31/07/2022 o hasta agotar stock de 1000
            unidades, lo que ocurra primero. Sujeto a disponibilidad técnica y
            geográfica. Oferta exclusiva para nuevos clientes residenciales. El
            Plan TV + Internet 1.000 megas + Telefonía, incluye los servicios de
            televisión digital, telefonía digital e internet wifi de hasta 1.000
            megas de velocidad de bajada y 30 Megas de velocidad de subida.
            Precio final mensual del plan: $4.999. Telecentro entregará módem en
            comodato. Costo de instalación $12.000 por única vez, en un pago por
            anticipado con tarjeta de crédito Visa, Mastercard o American
            Express. Consulte precio de instalación financiado con pago mediante
            tarjeta de crédito. Para más información comuníquese al teléfono
            6380-0000. La promoción incluye: I) Primeros 30 días de del Plan TV
            + Internet 1.000 megas + Telefonía sin cargo, a contar desde la
            instalación del servicio. II) Televisión digital a través de un
            Decodificador Video Sound Box para un Televisor, entregado por
            Telecentro en comodato. El decodificador Video Sound Box incluye
            acceso al servicio Alexa de Amazon y control remoto por voz. III)
            Pack HD: Primeros 3 meses sin cargo desde la activación del
            servicio. Finalizado el período promocional el Pack HD se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento. IV) El servicio de telefonía incluye 2.500 minutos por
            mes (no acumulables mensualmente) para llamadas locales a teléfonos
            fijos y llamadas sin cargo entre líneas de Telecentro. Las llamadas
            a líneas de telefonía móvil, de larga distancia nacional y larga
            distancia internacional se facturarán conforme a las tarifas
            vigentes al momento de las llamadas. V) Pack HBO: Primeros 2 meses
            sin cargo desde la activación del servicio y próximos 3 meses
            subsiguientes bonificación del 50% del precio de lista vigente en
            ese momento. Finalizado el período promocional el Pack HBO se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VI) Telecentro Wifi: se otorgan 50 GB sin
            cargo para utilizar el servicio Telecentro WiFi en la vía pública
            bajo la siguiente modalidad: 25 GB para utilizar en el transcurso
            del mes en el cual se instale el servicio y 25 GB para utilizar
            durante el mes inmediatamente siguiente. Finalizado el período
            promocional el servicio de Telecentro Wifi en vía pública, que
            incluye una cuota mensual de 25 GB para la utilización de este
            servicio, se renovará y facturará en forma mensual y sucesiva al
            precio de lista vigente en ese momento. VII) El cliente podrá
            activar el servicio de Amazon Prime Video accediendo a una
            bonificación en los 3 primeros meses desde la activación del
            servicio. Finalizado el período promocional Amazon Prime Video se
            renovará y facturará en forma mensual y sucesiva al precio de lista
            vigente en ese momento. VIII) El cliente podrá activar Hot Pack
            accediendo a una bonificación del precio total mensual del servicio
            correspondiente a los 2 primeros meses desde la activación del
            servicio y a una bonificación del 50% del precio mensual de lista
            vigente en ese momento, durante los próximos 2 meses subsiguientes.
            Finalizado el período promocional Hot Pack se renovará y facturará
            en forma mensual y sucesiva al precio de lista vigente en ese
            momento. En caso de arrepentimiento, el cliente tiene el derecho
            irrenunciable a revocar la aceptación del servicio contratado dentro
            de los diez días computados a partir de la suscripción al servicio,
            ingresando a Telecentro.com.ar y haciendo clic en el ¿Botón de
            Arrepentimiento¿. Para más información consulte en
            www.telecentro.com.ar/terminos Títulos: ¿Pack Digital, Pack HD y
            Productos Premium¿, ¿Aplicación Telecentro Play¿, ¿Telefonía¿, ¿Pack
            HBO¿, ¿Telecentro WiFi", ¿Amazon Prime Video¿ y ¿Hot Pack¿.
            TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San Justo, Buenos
            Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono: 11-6380-9500.
            Costo de instalación financiado con tarjeta de crédito:
            <br />
            <b>VISA y MASTERCARD</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.492,40</td>
                    <td>$ 13.477,20</td>
                    <td>$ 1.477,20</td>
                    <td>75,00%</td>
                    <td>102.99%</td>
                    <td>138,59%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.450,00</td>
                    <td>$ 14.700,00</td>
                    <td>$ 2.700,00</td>
                    <td>75,00%</td>
                    <td>103,64%</td>
                    <td>142,52%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.795,47</td>
                    <td>$ 16.159,20</td>
                    <td>$ 4.159,20</td>
                    <td>78,00%</td>
                    <td>109,68%</td>
                    <td>155,56%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.464,80</td>
                    <td>$ 17.577,60</td>
                    <td>$ 5.577,60</td>
                    <td>78,00%</td>
                    <td>109,80%</td>
                    <td>159,97%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b>AMERICAN EXPRESS</b>

            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.514,00</td>
                    <td>$ 13.542,00</td>
                    <td>$ 1.524,00</td>
                    <td>77,00%</td>
                    <td>108,00%</td>
                    <td>145,97%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.470,40</td>
                    <td>$ 14.822,40</td>
                    <td>$ 2.822,40</td>
                    <td>77,00%</td>
                    <td>107,97%</td>
                    <td>149,18%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.824,00</td>
                    <td>$ 16.416,00</td>
                    <td>$ 4.416,00</td>
                    <td>81,00%</td>
                    <td>116,07%</td>
                    <td>166,04%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.494,20</td>
                    <td>$ 17930,40</td>
                    <td>$ 5.930,40</td>
                    <td>81,00%</td>
                    <td>115,98%</td>
                    <td>170,69%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </p>
      </div>
      <div class="inner-section alexa-cliente">
        <h3>
          Telecentro con Alexa Integrada. Oferta de contratación para clientes
          activos
        </h3>
        <p>
          <span
            >Oferta válida para la República Argentina, desde el 28/04/2022
            hasta el 31/07/2022 o hasta agotar stock de 1.000 unidades, lo que
            ocurra primero. Sujeto a disponibilidad técnica y geográfica. Oferta
            exclusiva para clientes activos de servicios residenciales de
            Telecentro, suscriptos a planes que incluyan los servicios de
            Televisión digital, Internet con velocidad superior a 60 megas y
            Telefonía digital. El servicio incluye Televisión digital a través
            de un Decodificador Video Sound Box para un Televisor, entregado por
            Telecentro en comodato. El decodificador Video Sound Box incluye
            acceso al servicio Alexa de Amazon y control remoto por voz. Costo
            de instalación: $12.000 por única vez, en un pago por anticipado con
            tarjeta de crédito Visa, Mastercard o American Express. Consulte
            precio de instalación financiado con pago mediante tarjeta de
            crédito. Bonificación por cambio de decodificador 4k: en caso de que
            el Cliente opte por la devolución de un decodificador con resolución
            4K, previamente entregado en comodato por Telecentro, en óptimas
            condiciones de funcionamiento, el cargo inicial por la instalación
            del Equipo será de $ 8.000 en un pago o financiado en cuotas, según
            planes de financiación vigentes. Abono mensual por la prestación del
            Servicio: $1.000. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a Telecentro.com.ar y haciendo
            clic en el ¿Botón de Arrepentimiento¿. Para más información consulte
            en www.telecentro.com.ar. TELECENTRO S.A. Av. Juan Manuel de Rosas
            2860, San Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7.
            Teléfono: 11-6380-9500. Costo de instalación financiado con tarjeta
            de crédito para clientes activos de Telecentro:

            <br />
            <b>VISA y MASTERCARD</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.492,40</td>
                    <td>$ 13.477,20</td>
                    <td>$ 1.477,20</td>
                    <td>75,00%</td>
                    <td>102,99%</td>
                    <td>138,59%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.450,00</td>
                    <td>$ 14.700,00</td>
                    <td>$ 2.700,00</td>
                    <td>75,00%</td>
                    <td>103,64%</td>
                    <td>142,52%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.795,47</td>
                    <td>$ 16.159,20</td>
                    <td>$ 4.159,20</td>
                    <td>78,00%</td>
                    <td>109,68%</td>
                    <td>155,56%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.464,80</td>
                    <td>$ 17.577,60</td>
                    <td>$ 5.577,60</td>
                    <td>78,00%</td>
                    <td>109,80%</td>
                    <td>159,97%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b>AMERICAN EXPRESS</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 4.514,00</td>
                    <td>$ 13.542,00</td>
                    <td>$ 1.542,00</td>
                    <td>77,00%</td>
                    <td>108,00%</td>
                    <td>145,97%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 2.470,40</td>
                    <td>$ 14.822,40</td>
                    <td>$ 2.822,40</td>
                    <td>77,00%</td>
                    <td>107,97%</td>
                    <td>149,18%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.824,00</td>
                    <td>$ 16.416,00</td>
                    <td>$ 4.416,00</td>
                    <td>81,00%</td>
                    <td>116,07%</td>
                    <td>166,04%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 1.494,20</td>
                    <td>$ 17.930,00</td>
                    <td>$ 5.930,40</td>
                    <td>81,00%</td>
                    <td>115,98%</td>
                    <td>170,69%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            Costo de instalación financiado con tarjeta de crédito para clientes
            activos de Telecentro que suscriban a la bonificación parcial del
            costo de instalación por devolución de un decodificador 4K.

            <br />
            <b>VISA y MASTERCARD</b>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Cantidad de cuotas</th>
                    <th>Valor cuota</th>
                    <th>Precio total financiado</th>
                    <th>Interés</th>
                    <th>TNA</th>
                    <th>TEA</th>
                    <th>CFT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3</td>
                    <td>$ 2.994,93</td>
                    <td>$ 8.984,80</td>
                    <td>$ 984,40</td>
                    <td>75,00%</td>
                    <td>102,99%</td>
                    <td>138,59%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$ 1.633,33</td>
                    <td>$ 9.800,00</td>
                    <td>$ 1.800,00</td>
                    <td>75,00%</td>
                    <td>103,64%</td>
                    <td>142,52%</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>$ 1.196,98</td>
                    <td>$ 10.772,80</td>
                    <td>$ 2.772,80</td>
                    <td>78,00%</td>
                    <td>109,68%</td>
                    <td>155,56%</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>$ 976,53</td>
                    <td>$ 11.718,40</td>
                    <td>$ 3.718,40</td>
                    <td>78,00%</td>
                    <td>109,80%</td>
                    <td>159,97%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </p>
      </div>
      <div class="inner-section internet60">
        <h3>Internet Premium 60 Megas</h3>
        <p>
          <span
            >Oferta válida en la República Argentina desde el 01/06/22 hasta el
            31/07/2022 o hasta agotar stock de 5.000 unidades, lo que ocurra
            primero. Oferta sujeta a disponibilidad técnica y geográfica. Precio
            final mensual del plan: $1.599. El Plan INTERNET PREMIUM 60 MEGAS
            incluye: I) Internet wifi de hasta 60 megas de velocidad de bajada y
            15 Megas de velocidad de subida. Telecentro entregará módem en
            comodato. II) Primeros 30 días de del Plan sin cargo, a contar desde
            la instalación del servicio. III) Servicio de telefonía fija:
            Incluye 2.500 minutos por mes (no acumulables mensualmente) para
            llamadas locales a teléfonos fijos y llamadas sin cargo entre líneas
            de Telecentro. Las llamadas a líneas de telefonía móvil, de larga
            distancia nacional y larga distancia internacional se facturarán
            conforme a las tarifas vigentes al momento de las llamadas. IV)
            Telecentro Wifi: se otorgan 50 GB sin cargo para utilizar el
            servicio Telecentro WiFi en la vía pública bajo la siguiente
            modalidad: 25 GB para utilizar en el transcurso del mes en el cual
            se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a mayo de 2022: $383 final por
            mes). V) El cliente podrá activar el servicio Prime Video de Amazon,
            accediendo a una bonificación en los 3 primeros meses desde la
            activación del servicio. Finalizado el período promocional Prime
            Video se renovará y facturará en forma mensual y sucesiva al precio
            de lista vigente en ese momento (Precio correspondiente a mayo de
            2022: $386 final por mes). Oferta exclusiva para nuevos clientes
            residenciales. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a www.telecentro.com.ar y
            presionando en el BOTÓN DE ARREPENTIMIENTO. Para más información
            consulte en www.telecentro.com.ar/terminos, Títulos: TELECENTRO WIFI
            y PRIME VIDEO. TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San
            Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section internet150">
        <h3>Internet Premium 150 Megas</h3>
        <p>
          <span
            >Oferta válida en la República Argentina desde el 01/06/22 hasta el
            31/07/2022 o hasta agotar stock de 5.000 unidades, lo que ocurra
            primero. Oferta sujeta a disponibilidad técnica y geográfica. Precio
            final mensual del plan: $1.899. El Plan INTERNET PREMIUM 150 MEGAS
            incluye: I) Internet wifi de hasta 150 megas de velocidad de bajada
            y 30 Megas de velocidad de subida. Telecentro entregará módem en
            comodato. II) Primeros 30 días de del Plan sin cargo, a contar desde
            la instalación del servicio. III) Servicio de telefonía fija:
            Incluye 2.500 minutos por mes (no acumulables mensualmente) para
            llamadas locales a teléfonos fijos y llamadas sin cargo entre líneas
            de Telecentro. Las llamadas a líneas de telefonía móvil, de larga
            distancia nacional y larga distancia internacional se facturarán
            conforme a las tarifas vigentes al momento de las llamadas. IV)
            Telecentro Wifi: se otorgan 50 GB sin cargo para utilizar el
            servicio Telecentro WiFi en la vía pública bajo la siguiente
            modalidad: 25 GB para utilizar en el transcurso del mes en el cual
            se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a mayo de 2022: $383 final por
            mes). V) El cliente podrá activar el servicio Prime Video de Amazon,
            accediendo a una bonificación en los 3 primeros meses desde la
            activación del servicio. Finalizado el período promocional Prime
            Video se renovará y facturará en forma mensual y sucesiva al precio
            de lista vigente en ese momento (Precio correspondiente a mayo de
            2022: $386 final por mes). Oferta exclusiva para nuevos clientes
            residenciales. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a www.telecentro.com.ar y
            presionando en el BOTÓN DE ARREPENTIMIENTO. Para más información
            consulte en www.telecentro.com.ar/terminos, Títulos: TELECENTRO WIFI
            y PRIME VIDEO. TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San
            Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section internet300">
        <h3>Internet Premium 300 Megas</h3>
        <p>
          <span
            >Oferta válida en la República Argentina desde el 01/06/22 hasta el
            31/07/2022 o hasta agotar stock de 5.000 unidades, lo que ocurra
            primero. Oferta sujeta a disponibilidad técnica y geográfica. Precio
            final mensual del plan: $2.099. El Plan INTERNET PREMIUM 300 MEGAS
            incluye: I) Internet wifi de hasta 300 megas de velocidad de bajada
            y 30 Megas de velocidad de subida. Telecentro entregará módem en
            comodato. II) Primeros 30 días de del Plan sin cargo, a contar desde
            la instalación del servicio. III) Servicio de telefonía fija:
            Incluye 2.500 minutos por mes (no acumulables mensualmente) para
            llamadas locales a teléfonos fijos y llamadas sin cargo entre líneas
            de Telecentro. Las llamadas a líneas de telefonía móvil, de larga
            distancia nacional y larga distancia internacional se facturarán
            conforme a las tarifas vigentes al momento de las llamadas. IV)
            Telecentro Wifi: se otorgan 50 GB sin cargo para utilizar el
            servicio Telecentro WiFi en la vía pública bajo la siguiente
            modalidad: 25 GB para utilizar en el transcurso del mes en el cual
            se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a mayo de 2022: $383 final por
            mes). V) El cliente podrá activar el servicio Prime Video de Amazon,
            accediendo a una bonificación en los 3 primeros meses desde la
            activación del servicio. Finalizado el período promocional Prime
            Video se renovará y facturará en forma mensual y sucesiva al precio
            de lista vigente en ese momento (Precio correspondiente a mayo de
            2022: $386 final por mes). Oferta exclusiva para nuevos clientes
            residenciales. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a www.telecentro.com.ar y
            presionando en el BOTÓN DE ARREPENTIMIENTO. Para más información
            consulte en www.telecentro.com.ar/terminos, Títulos: TELECENTRO WIFI
            y PRIME VIDEO. TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San
            Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section internet500">
        <h3>Internet Premium 500 Megas</h3>
        <p>
          <span
            >Oferta válida en la República Argentina desde el 01/06/22 hasta el
            31/07/2022 o hasta agotar stock de 5.000 unidades, lo que ocurra
            primero. Oferta sujeta a disponibilidad técnica y geográfica. Precio
            final mensual del plan: $2.999. El Plan INTERNET PREMIUM 500 MEGAS
            incluye: I) Internet wifi de hasta 500 megas de velocidad de bajada
            y 30 Megas de velocidad de subida. Telecentro entregará módem en
            comodato. II) Primeros 30 días de del Plan sin cargo, a contar desde
            la instalación del servicio. III) Servicio de telefonía fija:
            Incluye 2.500 minutos por mes (no acumulables mensualmente) para
            llamadas locales a teléfonos fijos y llamadas sin cargo entre líneas
            de Telecentro. Las llamadas a líneas de telefonía móvil, de larga
            distancia nacional y larga distancia internacional se facturarán
            conforme a las tarifas vigentes al momento de las llamadas. IV)
            Telecentro Wifi: se otorgan 50 GB sin cargo para utilizar el
            servicio Telecentro WiFi en la vía pública bajo la siguiente
            modalidad: 25 GB para utilizar en el transcurso del mes en el cual
            se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a mayo de 2022: $383 final por
            mes). V) El cliente podrá activar el servicio Prime Video de Amazon,
            accediendo a una bonificación en los 3 primeros meses desde la
            activación del servicio. Finalizado el período promocional Prime
            Video se renovará y facturará en forma mensual y sucesiva al precio
            de lista vigente en ese momento (Precio correspondiente a mayo de
            2022: $386 final por mes). Oferta exclusiva para nuevos clientes
            residenciales. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a www.telecentro.com.ar y
            presionando en el BOTÓN DE ARREPENTIMIENTO. Para más información
            consulte en www.telecentro.com.ar/terminos, Títulos: TELECENTRO WIFI
            y PRIME VIDEO. TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San
            Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono:
            11-6380-9500.
          </span>
        </p>
      </div>
      <div class="inner-section internet1000">
        <h3>Internet Premium 1.000 Megas</h3>
        <p>
          <span
            >Oferta válida en la República Argentina desde el 01/06/22 hasta el
            31/07/2022 o hasta agotar stock de 5.000 unidades, lo que ocurra
            primero. Oferta sujeta a disponibilidad técnica y geográfica. Precio
            final mensual del plan: $3.799. El Plan INTERNET PREMIUM 1000 MEGAS
            incluye: I) Internet wifi de hasta 1000 megas de velocidad de bajada
            y 30 Megas de velocidad de subida. Telecentro entregará módem en
            comodato. II) Primeros 30 días de del Plan sin cargo, a contar desde
            la instalación del servicio. III) Servicio de telefonía fija:
            Incluye 2.500 minutos por mes (no acumulables mensualmente) para
            llamadas locales a teléfonos fijos y llamadas sin cargo entre líneas
            de Telecentro. Las llamadas a líneas de telefonía móvil, de larga
            distancia nacional y larga distancia internacional se facturarán
            conforme a las tarifas vigentes al momento de las llamadas. IV)
            Telecentro Wifi: se otorgan 50 GB sin cargo para utilizar el
            servicio Telecentro WiFi en la vía pública bajo la siguiente
            modalidad: 25 GB para utilizar en el transcurso del mes en el cual
            se instale el servicio y 25 GB para utilizar durante el mes
            inmediatamente siguiente. Finalizado el período promocional el
            servicio de Telecentro Wifi en vía pública, que incluye una cuota
            mensual de 25 GB para la utilización de este servicio, se renovará y
            facturará en forma mensual y sucesiva al precio de lista vigente en
            ese momento (Precio correspondiente a mayo de 2022: $383 final por
            mes). V) El cliente podrá activar el servicio Prime Video de Amazon,
            accediendo a una bonificación en los 3 primeros meses desde la
            activación del servicio. Finalizado el período promocional Prime
            Video se renovará y facturará en forma mensual y sucesiva al precio
            de lista vigente en ese momento (Precio correspondiente a mayo de
            2022: $386 final por mes). Oferta exclusiva para nuevos clientes
            residenciales. En caso de arrepentimiento, el cliente tiene el
            derecho irrenunciable a revocar la aceptación del servicio
            contratado dentro de los diez días computados a partir de la
            suscripción al servicio, ingresando a www.telecentro.com.ar y
            presionando en el BOTÓN DE ARREPENTIMIENTO. Para más información
            consulte en www.telecentro.com.ar/terminos, Títulos: TELECENTRO WIFI
            y PRIME VIDEO. TELECENTRO S.A. Av. Juan Manuel de Rosas 2860, San
            Justo, Buenos Aires, Argentina. CUIT nro. 30-64089726-7. Teléfono:
            11-6380-9500.
          </span>
        </p>
      </div>
    </div>
    <div class="terms-section">
      <h2>Contratos de adhesión - Ley 24.240 de Defensa del Consumidor</h2>
      <div
        class="inner-section servicio-de-television-digital-telefonia-digital-banda-ancha"
      >
        <h3>
          SERVICIO DE TELEVISIÓN DIGITAL, TELEFONÍA DIGITAL Y BANDA ANCHA.
          CONDICIONES GENERALES
        </h3>
        <p>
          <span
            >Las siguientes son las Condiciones generales del servicio
          </span>
        </p>
      </div>
      <div class="inner-section objeto">
        <h3>1. OBJETO</h3>
        <p>
          <span
            >1.1. En base a las presentes Condiciones Generales y la Solicitud
            de Servicio (cualquiera de ellos identificados en adelante como la
            Solicitud de Servicio, incluyendo cualquier anexo inherente a tales
            instrumentos), Telecentro S.A. (en adelante Telecentro), con
            domicilio en Brigadier Juan Manuel de Rosas 2860, San Justo
            Provincia de Buenos Aires, CUIT: 30-64089726-7, se compromete a
            prestar al cliente firmante de la Solicitud de Servicio (en adelante
            el CLIENTE), un Servicio consistente en:(I) Televisión digital
            (circuito cerrado).(II) Telefonía digital, proveyéndose las líneas
            telefónicas cuya cantidad y demás detalles se reflejan en la
            respectiva solicitud de servicio y (III) Banda ancha que permite el
            acceso a internet. Los servicios descriptos en (I) (II) (III) sean
            estos prestados en forma individual o conjuntamente, a todo efecto
            se denomina como Sistema.1.2. La suscripción de la Solicitud de
            Servicio por parte del Cliente implica la aceptación expresa de
            todas las condiciones de la misma.
          </span>
        </p>
      </div>
      <div class="inner-section datos-cliente">
        <h3>2. DATOS DEL CLIENTE</h3>
        <p>
          <span
            >A los efectos de esta Solicitud de Servicio, el CLIENTE es aquella
            persona física o jurídica que contrata a Telecentro el Sistema.)
            Datos necesarios : El CLIENTE se obliga a informar a Telecentro, en
            forma exacta y veraz, todos los datos personales que fueren
            necesarios para la activación y prestación del Sistema solicitado,
            los que se encuentran detallados en el formulario Solicitud de
            Servicio y a informar a Telecentro cualquier modificación o
            actualización que deba operarse sobre los mismos. De conformidad con
            lo establecido por la Ley N° 25.873, el cliente declara bajo
            juramento que los datos consignados son reales y completos
            haciéndose responsable civilmente en caso de su falsedad total.)
            Legitimación: El firmante de la Solicitud declara que, para el
            supuesto que actuara en nombre y representación de una persona
            física o jurídica en la contratación del Sistema, el poder que
            adjunta a la Solicitud, a tal fin y/o el que en su momento presentó
            a Telecentro, se encuentra vigente. Para el caso que así no fuera,
            el firmante de la Solicitud de Servicio y cualquier otra
            documentación, se obliga a responder ante Telecentro por cualquier
            reclamo extrajudicial y/o judicial que Telecentro recibiere por
            parte de la persona a la que dice representar, con causa en la
            falsedad de tal declaración. De suceder éste último evento,
            Telecentro interrumpirá el Sistema en forma inmediata y exigirá al
            firmante la restitución del Equipo afectado al Sistema dentro de las
            72 horas de notificado el requerimiento.) Documentación del cliente:
            Al solicitar el Sistema, el CLIENTE deberá presentar la siguiente
            documentación: Documento de Identidad legalmente válido;
            certificación de domicilio y toda otra que resulte necesaria
            integrar por imposición de la normativa vigente o por requerimiento
            de Telecentro. Asimismo, el CLIENTE se obliga, expresa e
            irrevocablemente, a entregar -junto con la Solicitud de Servicio-
            copias de sus formularios CUIT y documentación relacionada con el
            Impuesto a Ingresos Brutos, según correspondiere. En caso de no
            cumplir fiel y puntualmente con la obligación aquí asumida, el
            CLIENTE acepta ser considerado, sin necesidad de comunicación
            alguna, como consumidor final o sujeto no categorizado) Análisis
            Crediticio: El CLIENTE toma conocimiento y acepta que Telecentro -en
            forma previa a la firma de la Solicitud por el CLIENTE- tiene
            facultad de realizar un análisis de la calificación crediticia del
            CLIENTE, por lo que Telecentro se reserve el derecho de no aceptar
            la contratación del Sistema si el CLIENTE no calificara
            crediticiamente, sin que tal rechazo dé derecho a indemnización
            alguna a favor de este último.
          </span>
        </p>
      </div>
      <div class="inner-section servicio">
        <h3>3. SERVICIO</h3>
        <p>
          <span
            >3.1- El Sistema será prestado por medio de equipamientos y recursos
            humanos técnicos propios de TELECENTRO o contratados por ésta. El
            sistema será utilizado para uso exclusivo del CLIENTE en el
            domicilio indicado en la Solicitud de Servicio, quien en ningún caso
            podrá modificar el destino del mismo.\n3.2. Comodato: El CLIENTE
            declara conocer que el cableado interno instalado en su domicilio es
            una extensión de la red de TELECENTRO, todos sus componentes son
            propiedad de TELECENTRO, incluyendo los Equipos Electrónicos que
            allí se instalen, reservándose TELECENTRO el derecho de utilizar
            para sí o terceros las facilidades adicionales que los mismos puedan
            brindar para los distintos Servicios de Tecnologías de la
            Información y las Comunicaciones (Servicios TIC).&nbsp;El uso de los
            Equipos instalados y todos los componentes de la red interna se
            regirán por las normas del Comodato (Arts. 1533 y subsiguientes del
            Código civil y Comercial). Finalizada la prestación del servicio
            TELECENTRO procederá a retirar los Equipos Electrónicos previa
            coordinación con el Cliente. TELECENTRO entrega al CLIENTE en
            comodato el Equipo detallado en la Solicitud de Servicio anexa y que
            es necesario para el Sistema. El CLIENTE se compromete a mantener el
            Equipo en el mismo estado de uso y conservación en que le fue
            entregado. El cliente declara conocer que cada Equipo comprende
            tanto al cablemódem en sí, su fuente de alimentación y cualquier
            otro elemento adicional que lo integre. Por ello, si se produjere la
            pérdida y/o deterioro total o parcial del Equipo, el CLIENTE deberá
            pagar a TELECENTRO el valor del mismo. A todo evento el CLIENTE
            declara tomar conocimiento y aceptar, que el valor de reposición de
            cada equipo es de Dólares Estadounidenses Cien (U$S 100). En caso
            que el CLIENTE no devuelva alguno de los componentes del Equipo
            deberá abonar el valor del faltante, estipulándose en U$S 15 el
            valor de la fuente de alimentación. Queda a criterio del CLIENTE la
            contratación a su exclusivo costo y cargo de un seguro que cubra el
            valor del Equipo por todos los riegos posibles (daño total o parcial
            por cualquier causal, robo, hurto, etc) dicha póliza deberá tener
            como beneficiario a TELECENTRO. La contratación del mentado seguro
            no exime al CLIENTE de su responsabilidad como obligado principal
            pagador. Cualquier incumplimiento total o parcial del CLIENTE a sus
            obligaciones asumidas en estas Condiciones Generales y/o en la
            Solicitud de Servicios, facultará a TELECENTRO de pleno derecho a
            rescindir el comodato y exigir la restitución del Equipo. La falta
            de restitución del Equipo producirá los efectos de la retención
            indebida, siendo aplicable lo dispuesto por el art. 173 del Código
            Penal. Sin perjuicio de lo expuesto y en concepto de Cláusula Penal,
            en caso que el CLIENTE no devolviera el equipo en tiempo y forma
            fijados en la interpelación pertinente, el CLIENTE queda obligado al
            pago de una multa de Dólares Estadounidenses cinco (U$S 5) por cada
            día de demora en la efectiva devolución. El importe resultante será
            independiente del que deba pagarse por el valor del Equipo fijado en
            la Cláusula Quinta y las restantes deudas liquidas y exigibles por
            servicios y/u otras cargos ya devengados.3.3. TELECENTRO declara dar
            expreso cumplimiento a lo dispuesto en los Art. 76 Inc. b y 77 Inc.
            c del Reglamento de Clientes de los Servicios TIC Resolución
            733/2017, que establecen la obligatoriedad de ofrecer un software de
            protección que impida el acceso a sitios específicos de internet, y
            el servicio de bloqueos de contenidos, aplicaciones de información y
            entretenimientos o servicios de control parental.
          </span>
        </p>
      </div>
      <div class="inner-section vigencia">
        <h3>4. VIGENCIA</h3>
        <p>
          <span
            >4.1. El plazo de vigencia de la Solicitud de Servicio, sin
            perjuicio de la fecha de su celebración y firma, se iniciará a
            partir de la fecha de instalación del Sistema y permanecerá vigente
            por el plazo de TRES (3) meses con renovación automática,
            sucesivamente y por igual plazo, al vencimiento de cada periodo.
            4.2. El cliente tiene derecho a revocar la contratación del Sistema
            durante el pazo de DIEZ (10) días corridos, contados a partir de la
            fecha de firma de la presente Solicitud de Servicio. El CLIENTE
            deberá comunicar fehacientemente dicha revocación a Telecentro, y
            para ejercer el derecho de revocación, el CLIENTE deberá poner a
            disposición de Telecentro el Equipo que hubiere recibido de esta
            última, sin haberlos usado y manteniéndolos en el mismo estado que
            lo recibió. Telecentro deberá reintegrar al cliente -si así fuere el
            caso- los importes recibidos de él, con causa en la Solicitud de
            Servicio.
          </span>
        </p>
      </div>
      <div class="inner-section precio-condiciones-pago">
        <h3>5. PRECIO Y CONDICIONES DE PAGO</h3>
        <p>
          <span
            >5.1. El cliente deberá abonar por mes adelantado a Telecentro las
            sumas que se detallan en la Solicitud de Servicio: Cargo inicial por
            la instalación del Sistema; Cargo de activación del Servicio
            Telefónico Digital; Abono mensual por la prestación del Sistema;
            5.2. El CLIENTE deberá abonar por mes vencido a Telecentro las sumas
            que se detallan en la Solicitud de Servicio: Los consumos del
            Servicio Telefónico Digital que realice conforme las tarifas
            vigentes, tanto para las comunicaciones locales, como las de larga
            distancia nacional, las de telefonía celular y cualquier otra
            habilitada por el Sistema. 5.3. Los vencimientos correspondientes al
            servicio contratado se efectuará entre los días 7 y 10 de cada mes.
            La factura enviada mensualmente al CLIENTE indicará el efectivo
            vencimiento de la misma. El CLIENTE deberá realizar los pagos dentro
            de la fecha establecida en cada factura y en cualquiera de los
            lugares o a través de los medios, que Telecentro habilite al efecto.
            El cierre del ciclo de facturación se efectúa entre los días 26 y 28
            de cada mes. La falta de pago de cualquier monto adeudado al
            vencimiento del plazo establecido en la factura correspondiente,
            hará incurrir al CLIENTE en mora automática de pleno derecho, sin
            necesidad de interpelación judicial o extrajudicial alguna, y
            autorizará a Telecentro a aplicar un cargo fijo mensual de Pesos
            Treinta ($30) por pago fuera de término. Ante la disconformidad del
            CLIENTE por el cargo aplicado bajo tal concepto, Telecentro podrá
            aplicar el interés equivalente a la tasa pasiva para depósitos a
            TREINTA (30) días utilizada por el Banco de la Nación Argentina,
            incrementada hasta un 50% (cincuenta por ciento), correspondiente al
            último día del mes anterior a la efectivización del pago (por
            aplicación analógica de lo establecido en el art. 12 de la Ley N°
            26.361, modificatorio del art. 31 de la Ley N° 24.240). La recepción
            por parte de Telecentro de cualquier pago parcial no implicará la
            purga ni condonación de mora del CLIENTE. 5.4. A las tarifas y
            precios facturados por mes adelantado o vencido, se adicionarán
            todos los gastos que correspondieren por impuestos, tasas, cargos,
            derechos o cualquier otro tipo de gravamen nacional, provincial o
            municipal, presente o futuro que graven el Sistema y que de acuerdo
            a la normativa tributaria vigente, correspondiere en forma exclusiva
            al CLIENTE. 5.5. Las tarifas de llamadas podrán ser consultadas vía
            internet en el sitio: www.Telecentro.com.ar y mediante acceso
            telefónico gratuito llamando al: 011-6380-9500. 5.6. Los precios,
            descuentos, y/o bonificaciones que se encuentren incluidos en los
            precios de la Solicitud de Servicio mantendrán su vigencia solo en
            el caso que el CLIENTE optare por mantener las líneas asignadas al
            Sistema como presuscriptas al Servicio de Larga Distancia Nacional e
            Internacional de Telecentro de acuerdo al Reglamento General de
            Presuscripción del Servicio de Larga Distancia, Resolución SC N°
            2724/98. 5.7. Telecentro podrá modificar el precio de todos sus
            productos y servicios y demás conceptos actuales y futuros que
            comprendan el Sistema utilizado por el CLIENTE. De acuerdo a lo
            dispuesto en el art. 3 de la Resolución N° 1150/2019 modificatorio
            del art. 30 de la Resolución N° 733/2017, Telecentro informará los
            nuevos precios al CLIENTE con quince (15) días corridos de
            anticipación a su entrada en vigencia en la página web
            www.Telecentro.com.ar/sucursal-virtual. En caso que el CLIENTE no
            acepte la modificación podrá rescindir sin cargo el Sistema,
            comunicando dicha decisión a Telecentro. 5.8. En caso de baja,
            corresponderá facturar y abonar la totalidad del consumo
            correspondiente al mes en que opera la baja del Sistema.''</span
          >
        </p>
      </div>
      <div class="inner-section responsabilidad">
        <h3>6. RESPONSABILIDAD</h3>
        <p>
          <span
            >6.1. El CLIENTE se compromete a utilizar el Sistema conforme los
            términos y condiciones establecidos en la presente Solicitud de
            Servicio, si así no lo hiciere, Telecentro se encuentra habilitada a
            proceder a la suspensión preventiva del Sistema. 6.2. El CLIENTE se
            compromete a no efectuar conexiones no autorizadas al Sistema,
            equipos y red de Telecentro y a cumplir con las solicitudes de esta
            última dirigidas a la reparación o reemplazo de equipos terminales
            cuando estos afecten a los equipos y/ o a la red de Telecentro. El
            CLIENTE reconoce que no tiene derecho de propiedad alguno sobre el
            número de la línea o líneas telefónicas asignadas por Telecentro
            para la utilización del sistema, sin perjuicio del derecho a la
            portabilidad numérica conforme normativa vigente. 6.3. El CLIENTE
            deberá permitir que el personal designado por Telecentro tenga en
            cualquier momento libre y seguro acceso al domicilio de instalación
            del Sistema, a los efectos de verificar el correcto funcionamiento y
            uso del sistema previa coordinación con el CLIENTE de la fecha y
            horario de visita. 6.4. Limitaciones del Servicio Telefonía Digital:
            El CLIENTE reconoce que, en razón de la tecnología propia del
            Sistema, éste no remplaza ni es asimilable al Servicio Básico
            Telefónico (SBT) y que su utilización implica determinadas
            limitaciones a saber:(I) El acceso a la línea telefónica del CLIENTE
            no soporta la utilización de modem telefónico y no está garantizada
            la recepción y envío de faxes; (II) Los sistemas de alarmas
            domiciliarios o similares que utilizan las líneas telefónicas del
            CLIENTE para comunicarse con una central por medio del envío de
            tonos DTMF no se encuentran homologados por Telecentro, no estando
            en consecuencia garantizado su funcionamiento; (III)Telecentro sólo
            garantiza el acceso a los números de emergencia correspondientes al
            área local a la que pertenece la numeración de la línea solicitada;
            (IV) La falta de disponibilidad del Sistema imputable a Telecentro
            autorizará al CLIENTE a obtener una compensación según lo
            establecido para estos casos en el RGCSBT S.C.Nº 10.059/99. y RGCSTI
            733/2017.(V) Telecentro no será responsable por las interrupciones
            del Sistema si se deben a:) Averías masivas ajenas a la
            responsabilidad de Telecentro) Actos y/u omisiones del CLIENTE o de
            sus dependientes o usuarios;) Causas ajenas a la responsabilidad de
            Telecentro. Cortes del suministro de energía eléctrica. En virtud de
            las características técnicas del Sistema, el CLIENTE declara conocer
            que el Sistema se interrumpirá en caso de cortes del suministro de
            energía eléctrica en el domicilio del CLIENTE. Respecto del Servicio
            Telefonía Digital, temporalmente la falta de energía eléctrica podrá
            ser suplida mediante la conexión del equipo a una fuente de poder
            alternativa, la cual el CLIENTE puede adquirir por su cuenta y orden
            como equipamiento adicional.6.5. Para el Servicio de Telefonía
            Digital| el CLIENTE es responsable de proporcionar el aparato
            terminal de telefonía y la provisión de cualquier equipo o
            dispositivo asociado a servicios adicionales como centrales
            telefónicas, módems, etc.6.6. Telecentro no será responsable por el
            no funcionamiento o mal funcionamiento de aplicaciones provistas por
            terceras partes que requieran la utilización de una IP pública
            asignada directamente sobre el dispositivo del usuario (sea una pc o
            hardware especifico). En particular, dado que la tecnología conocida
            como NAT (Network Address Translation) se utiliza para compartir una
            IP pública entre varias lps privadas, existe la posibilidad que
            ciertas aplicaciones no funcionen simultáneamente en más de una PC.
            Cabe aclarar que esta no es una limitación impuesta por el Sistema,
            objeto del presente Contrato, sino por el servicio al cual se
            intenta acceder o la aplicación que se intenta utilizar.6.7. En el
            uso del CLIENTE del servicio de Banda Ancha de Telecentro, ésta
            declina toda responsabilidad relacionada con la calidad, exactitud,
            fiabilidad y corrección de los datos, programas e información de
            cualquier tipo que circulen por las redes. El contenido de dicha
            información es de exclusiva responsabilidad de los sujetos que la
            intercambian (remitente y destinatario). En consecuencia, Telecentro
            deja expresamente aclarado que no será responsable por cualquier
            daño y/o perjuicio reclamado judicial y/o extrajudicialmente
            motivado por el uso del CLIENTE o de terceros, de los servicios de
            la red de los que resulte o pueda resultar una infracción a
            eventuales derechos intelectuales de propiedad de terceros.6.8. El
            CLIENTE asume plena responsabilidad frente a Telecentro y terceros
            por los daños y perjuicios de toda clase que se generen como
            consecuencia del accionar propio, de sus dependientes o de terceros
            conectados a través del Sistema del CLIENTE, que resulten o cuando
            sean utilizados como instrumentos de hechos ilícitos, debiendo el
            CLIENTE indemnizar y mantener indemne a Telecentro ante cualquier
            reclamo de pago que pudiera corresponder en los supuestos
            indicados.</span
          >
        </p>
      </div>
      <div class="inner-section terminacion">
        <h3>7. TERMINACION ANTICIPADA. RESCISIÓN.</h3>
        <p>
          <span
            >7.1. A requerimiento del CLIENTE: En cualquier momento sin
            invocación de causa alguna, sin perjuicio de la obligación del
            CLIENTE de abonar todos sus consumos y cargos devengados hasta la
            fecha efectiva de baja del servicio.\n7.2. Por incumplimiento de
            TELECENTRO: El CLIENTE podrá dar por terminada la prestación del
            Sistema si TELECENTRO incumple con cualquiera de sus obligaciones
            emergentes de la prestación del Sistema y no subsane tal
            incumplimiento dentro del quinto día de haber sido intimada al
            efecto en forma fehaciente.\n7.3 Por incumplimiento del CLIENTE: En
            caso de que el CLIENTE incumpla con su obligación de pago de
            cualquier factura dentro de los TREINTA (30) días corridos
            posteriores a su vencimiento, TELECENTRO tendrá el derecho a
            interrumpir total o parcialmente el Sistema y/o -respecto del
            Servicio de Telefonía Digital- suspender las comunicaciones
            salientes del CLIENTE, excepto las cursadas a servicios de
            emergencia. En forma previa a concretar la suspensión de las
            comunicaciones salientes del CLIENTE, TELECENTRO deberá Informar al
            mismo al menos en dos oportunidades acerca de este hecho,
            telefónicamente o por otros medios .Transcurridos SESENTA (60) días
            del vencimiento de la factura, TELECENTRO podrá dar de baja el
            Sistema, previa notificación al CLIENTE con al menos 5 (cinco) días
            hábiles de anticipación a la fecha efectiva de baja, haciéndose
            exigibles todas las sumas que se adeuden a la fecha.
          </span>
        </p>
      </div>
      <div class="inner-section fuerza-mayor">
        <h3>8. FUERZA MAYOR</h3>
        <p>
          <span
            >8.1. Si el CLIENTE o Telecentro se vieran afectados por hechos
            encuadrables como Fuerza Mayor o Caso Fortuito, la parte que se
            viere afectada podrá notificar a la otra por escrito dentro del
            plazo de cinco (5) días del suceso. Durante todo el tiempo en que el
            hecho de fuerza mayor o caso fortuito en cuestión torne imposible el
            cumplimiento de sus obligaciones, las mismas se suspenderán. En caso
            de que el hecho de Fuerza Mayor o Caso Fortuito se extendiere por
            más de TREINTA (30) días impidiendo el normal restablecimiento de
            las relaciones emergentes entre el CLIENTE y Telecentro, cualquiera
            de ellos podrá notificar su decisión de terminar en forma inmediata
            la relación, sin incurrir en ninguna responsabilidad como
            consecuencia de tal terminación anticipada.8.2. Conforme lo
            establecido en el acápite 6.4 (V) e), queda incluido dentro del
            concepto de Fuerza Mayor los cortes de energía eléctrica que afecten
            la prestación del sistema en el domicilio del CLIENTE o de
            Telecentro.
          </span>
        </p>
      </div>
      <div class="inner-section provision-asistencia-reclamos">
        <h3>9. PROVISIÓN DEL SISTEMA. ASISTENCIA TÉCNICA Y RECLAMOS</h3>
        <p>
          <span
            >9.1. El Sistema será prestado y facturado por TELECENTRO 9.2 El
            CLIENTE tendrá a su disposición un Centro de Atención que funcionará
            durante las 24 horas de los 365 días del año, mediante el cual
            comunicará o formulará cualquier inquietud o reclamo relativo a
            problemas en el Sistema. En tal caso podrá llamar sin cargo:
            Atención al Cliente 011-6380-9500 o por Internet
            www.telecentro.com.ar. Email de contacto por consultas y/o reclamos:
            clientes@telecentro.net.ar 9.3. Por tratarse el Sistema de Servicios
            de Telecomunicaciones, el CLIENTE podrá encaminar sus reclamos a:
            Ente Nacional de Comunicaciones (ENACOM), llamando sin cargo al
            0800-333-3344. 9.4 Ley de Defensa del Consumidor:
            <a
              href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/0-4999/638/texact.htm"
              target="_blank"
              rel="noopener noreferrer"
              >http://servicios.infoleg.gob.ar/infolegInternet/anexos/0-4999/638/texact.htm</a
            >
            9.5 Dirección General de Defensa y Protección al Consumidor:
            <a
              href="https://www.buenosaires.gob.ar/defensaconsumidor/direccion-general"
              target="_blank"
              rel="noopener noreferrer"
              >https://www.buenosaires.gob.ar/defensaconsumidor/direccion-general</a
            >
            9.6 Reglamento de Clientes ¿ Servicios TIC:
            <a
              href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/305000-309999/305484/texact.htm"
              target="_blank"
              rel="noopener noreferrer"
              >http://servicios.infoleg.gob.ar/infolegInternet/anexos/305000-309999/305484/texact.htm</a
            >
            Dirección Nacional de Defensa del consumidor. Dirección: Av. Julio
            A. Roca 651 (1322). Por e-mail a consultas@consumidor.gov.ar o
            llamando sin cargo al 0800-666-1518. Dirección Provincial de
            Comercio- Torre Gubernamental II Piso 12 Calle 12 Esq. 53 La Plata -
            Buenos Aires -Tel. 0800-222-9042.
          </span>
        </p>
      </div>
      <div class="inner-section cesion">
        <h3>10. CESIÓN</h3>
        <p>
          <span
            >El CLIENTE no podrá ceder total o parcialmente a terceros los
            números telefónicos asignados ni sus derechos y obligaciones
            contenidos en la Solicitud de Servicio sin contar con el
            consentimiento por escrito de Telecentro.
          </span>
        </p>
      </div>
      <div class="inner-section condiciones-finales">
        <h3>11. CONDICIONES FINALES</h3>
        <p>
          <span
            >11.1. Los términos y condiciones aquí establecidas son válidos en
            sí mismos y no invalidará el resto. La cláusula inválida o
            incompleta podrá ser sustituida por otra equivalente y válida por
            acuerdo de las partes. 11.2. En caso que el CLIENTE contrate
            servicios adicionales no especificados en estas Condiciones
            Generales y/o en la Solicitud de Servicio, los mismos se regirán por
            sus respectivas condiciones de contratación. 11.3. Los Anexos que se
            acompañan a la presente solicitud forman parte integrante de la
            misma.
          </span>
        </p>
      </div>
      <div class="inner-section ley-y-jurisdiccion">
        <h3>12. LEY Y JURISDICCIÓN</h3>
        <p>
          <span
            >La Solicitud de Servicio y la relación entre el CLIENTE y
            Telecentro se regirá e interpretará de acuerdo con las leyes de la
            República Argentina.. A todos los efectos derivados de la Solicitud
            de Servicio y la relación entre el CLIENTE y Telecentro que
            sobrepasen la vía administrativa, las Partes se someten a la
            jurisdicción de los Tribunales Ordinarios correspondientes al
            domicilio del Cliente o de los Tribunales competentes en razón de la
            materia, en cuyo caso la competencia territorial resultará la del
            fuero correspondiente al domicilio del cliente. Las Partes
            constituyen domicilios especiales en los indicados en la Solicitud
            de Servicio, donde se considerarán válidas tocias las comunicaciones
            que entre ellas se cursen.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Terms-Policy",
};
</script>
